export function IconOnePageX() {
	return (
		<svg viewBox="0 0 20 20" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<rect x="5" y="6.25" width="10" height="1.5" />
			<rect x="5" y="9.25" width="10" height="1.5" />
			<rect x="5" y="12.25" width="5.75" height="1.5" />
			<rect x="2" y="2" width="16" height="1.5" />
			<rect x="2" y="2" width="1.5" height="16" />
			<rect x="16.5" y="2" width="1.5" height="8.75" />
			<rect x="2" y="16.5" width="8.75" height="1.5" />
			<path d="M 12.362 12.448 C 12.616 12.194 13.027 12.194 13.283 12.448 L 16.081 15.221 L 18.88 12.448 C 19.228 12.09 19.836 12.24 19.975 12.718 C 20.043 12.948 19.972 13.195 19.801 13.358 L 17.002 16.132 L 19.801 18.906 C 20.148 19.263 19.978 19.86 19.489 19.98 C 19.271 20.033 19.042 19.973 18.88 19.816 L 16.081 17.045 L 13.283 19.816 C 12.924 20.165 12.319 19.991 12.198 19.509 C 12.144 19.294 12.206 19.067 12.362 18.906 L 15.159 16.132 L 12.362 13.358 C 12.108 13.107 12.108 12.699 12.362 12.448 Z" />
		</svg>
	);
}

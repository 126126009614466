import { useCallback, useMemo, useState } from 'react';
import { TableRow } from '@ipis-ui-kit/table-types';
import { Modal } from '../../../modal/Modal';
import { Button } from '../../../button/Button';
import { IconClose } from '../../../icons';
import { Select } from '../../../select/Select';
import { FilterInputsDictionary, TableFilterModalProps } from './TableFilterModal.types';
import { TableSelectedFilter } from './TableFilter.types';
import { StackPanel } from '../../../panel/StackPanel';

export function TableFilterModal<TFilterValue, TRow extends TableRow>(
	props: TableFilterModalProps<TFilterValue, TRow>,
): JSX.Element {
	const { testid, columnKey, title, filters, selectedFilters, onFiltersSelected, onCancel } = props;

	const filterInputs: FilterInputsDictionary<TFilterValue> = useMemo(
		() => filters.reduce((a, { title: filterTitle, input }) => ({ ...a, [filterTitle]: input }), {}),
		[filters],
	);

	const [currentFilters, setCurrentFilters] = useState<TableSelectedFilter<TFilterValue>[]>(() =>
		selectedFilters.length ? selectedFilters.map((x) => ({ ...x })) : [{ filterTitle: filters[0].title }],
	);

	const onSubmit = useCallback(
		() => onFiltersSelected(columnKey!, currentFilters),
		[currentFilters, columnKey, onFiltersSelected],
	);

	const getIndex = (filter: TableSelectedFilter<TFilterValue>, i: number) => filter.filterTitle + i;

	return (
		<Modal testid="data-table-filter-modal" width="medium" onClose={onCancel} onSubmit={onSubmit}>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Body>
				<div data-testid="modal-body-container">
					{currentFilters.map((selectedFilter, i) => (
						<div key={getIndex(selectedFilter, i)} className="mb-2 flex gap-2">
							<div className="w-1/3">
								<Select
									testid={`${testid}-filter-${i}`}
									selectedId={selectedFilter.filterTitle}
									items={filters.map((f) => ({ id: f.title, value: f.title }))}
									onChange={(newValue) =>
										setCurrentFilters((prev) =>
											prev.map((filter, j) => (j === i ? { ...filter, filterTitle: newValue.value } : filter)),
										)
									}
								/>
							</div>

							<div className="w-2/3">
								{filterInputs[selectedFilter.filterTitle](selectedFilter.value, (newValue) =>
									setCurrentFilters((prev) => prev.map((filter, j) => (j === i ? { ...filter, value: newValue } : filter))),
								)}
							</div>

							<span
								role="presentation"
								data-testid={`${testid}-filter-${i}-delete-button`}
								className={`flex w-5 flex-shrink-0 items-center
									${currentFilters.length === 1 ? 'text-grayscale-300' : 'cursor-pointer text-danger'}`}
								onClick={() => currentFilters.length > 1 && setCurrentFilters((prev) => prev.filter((_, j) => j !== i))}
							>
								<IconClose />
							</span>
						</div>
					))}
					<Button
						testid={`${testid}-add-filter-button`}
						variant="outlined"
						onClick={() => setCurrentFilters((prev) => [...prev, { filterTitle: filters[0].title }])}
					>
						Add condition
					</Button>
				</div>
			</Modal.Body>
			<Modal.Footer itemsPosition="right">
				<StackPanel direction="horizontal" testid={`${testid}`}>
					<Button testid={`${testid}-filter-cancel`} variant="outlined" onClick={onCancel}>
						Cancel
					</Button>
					<Button testid={`${testid}-filter-apply`} onClick={onSubmit}>
						Apply
					</Button>
				</StackPanel>
			</Modal.Footer>
			<Modal.Overlay />
		</Modal>
	);
}

import { ODataTableFilter, stringExpressions } from '@ipis-ui-kit/odata-table';
import { selectInput } from '../../ui/filtration/TableFilterModalInputs';

export const eq = (typeEnum: Record<string, string>): ODataTableFilter<string> => ({
	title: 'equals',
	apply: stringExpressions.equals(),
	input: selectInput(typeEnum),
});

export const ne = (typeEnum: Record<string, string>): ODataTableFilter<string> => ({
	title: 'does not equal',
	apply: stringExpressions.notEquals(),
	input: selectInput(typeEnum),
});

const filters = [eq, ne];

export const all = (typeEnum: Record<string, string>) => filters.map((filter) => filter(typeEnum));

import { Listbox } from '@headlessui/react';
import { SelectItemProps } from './SelectItem.types';

export function SelectItem(props: SelectItemProps): JSX.Element {
	const { testid, item } = props;

	return (
		<Listbox.Option disabled={item.disabled} value={item} className="cursor-pointer font-light text-grayscale-700 outline-none">
			{({ active, disabled }) => (
				<div
					data-testid={`${testid}-${item.id}-option`}
					className={`flex items-center gap-2 px-3 py-2
						${active && 'bg-grayscale-200 font-medium'}
						${disabled ? 'text-grayscale-300' : 'hover:bg-grayscale-200'}`}
				>
					{item.icon && <div className="w-5 flex-shrink-0">{item.icon}</div>}
					<div>{item.value}</div>
				</div>
			)}
		</Listbox.Option>
	);
}

export function IconPerson() {
	return (
		<svg width="100%" viewBox="0 0 14 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.75 3.75a3.25 3.25 0 1 0 6.5 0 3.25 3.25 0 0 0-6.5 0zm-2.642 6.489v.017L.5 14.583h13l-.605-4.311-.002-.033c0-1.23-.933-2.156-2.17-2.156H8.73L7 12.236 5.27 8.083H3.278c-1.236 0-2.17.927-2.17 2.156z"
				fill="currentColor"
			/>
			<defs>
				<linearGradient id="paint0_linear" x1="7" y1=".5" x2="7" y2="14.583" gradientUnits="userSpaceOnUse">
					<stop stopColor="#fff" />
					<stop offset="1" stopColor="#fff" />
				</linearGradient>
			</defs>
		</svg>
	);
}

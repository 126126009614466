/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMemo } from 'react';
import { TableRow, TableColumn } from '@ipis-ui-kit/table-types';
import { TableHeader } from './TableHeader';
import { IconDots, IconSettings } from '../../icons';
import { CheckBox } from '../../checkbox/CheckBox';
import { TableColumnsMap, TableRootProps } from './TableRoot.types';
import { TableHeaderCheckBox } from './TableHeaderCheckBox';

export function TableRoot<TRow extends TableRow>(props: TableRootProps<TRow>): JSX.Element {
	const { testid, columns, data, hideSettings, hideRowOptions, noResize } = props;
	const { filter, orderSettings, hiddenColumns, columnsOrder, columnSizes, selectable } = props;
	const { onSettingsMenu, onRowContextMenu, onRowDoubleClick } = props;
	const { onFilterClear, onFilterSet, onOrderChanged, onSelectedRowChange, onAllRowsSelect, onSelectedRowsClear, onColumnResize } = props; // prettier-ignore

	const rowKeyColumns = columns.filter((x) => x.isRowKey).map((x) => x.columnKey);

	if (rowKeyColumns.length === 0) throw Error('The table should have a row key');
	if (rowKeyColumns.length > 1) throw Error('The table should have only one row key');

	const [rowKey] = rowKeyColumns;

	const isHiddenColumn = (column: TableColumn<TRow>) => hiddenColumns && hiddenColumns.has(column.columnKey);
	const displayOptionsPanel = !hideRowOptions || !hideSettings;

	const columnsMap = useMemo<TableColumnsMap<TRow>>(
		() => columns.reduce((acc, cur) => ({ ...acc, [cur.columnKey]: cur }), {}),
		[columns],
	);

	const currentPageRows = useMemo(() => new Set(data.map((x) => x[rowKey] as string | number)), [data, rowKey]);
	const isAllRowsOnPageSelected = useMemo(
		() => (selectable ? [...currentPageRows].every((x) => selectable.selectedRows.has(x)) : false),
		[currentPageRows, selectable],
	);
	const isAnyRowOnPageSelected = useMemo(
		() => (selectable ? [...currentPageRows].some((x) => selectable.selectedRows.has(x)) : false),
		[currentPageRows, selectable],
	);

	return (
		<div className="relative overflow-hidden">
			<div data-testid={`${testid}-table-container`} className={`overflow-x-auto ${displayOptionsPanel && 'pr-9'}`}>
				<table className="w-max min-w-full table-fixed text" data-testid={`${testid}-table`}>
					<thead className="bg">
						<tr>
							{selectable && (
								<TableHeaderCheckBox
									testid={testid}
									disabled={data.length === 0}
									selectAllDisabled={selectable.selectAllDisabled}
									allRowsSelected={selectable.allRowsSelected}
									anyRowSelected={selectable.selectedRows.size > 0}
									anyRowOnPageSelected={isAnyRowOnPageSelected}
									allRowsOnPageSelected={isAllRowsOnPageSelected}
									onAllRowsSelect={onAllRowsSelect ?? (() => {})}
									onCurrentPageRowsSelect={() => onSelectedRowChange?.(currentPageRows, true)}
									onCurrentPageRowsClear={() => onSelectedRowChange?.(currentPageRows, false)}
									onSelectionClear={onSelectedRowsClear ?? (() => {})}
								/>
							)}

							{columnsOrder?.map((columnKey) => (
								<TableHeader
									key={columnKey.toString()}
									testid={`${testid}-${columnKey.toString()}`}
									title={columnsMap[columnKey]?.title ?? ''}
									widthPreset={columnsMap[columnKey]!.width}
									filter={filter?.[columnKey]}
									size={columnSizes?.[columnKey]}
									order={orderSettings?.[columnKey]?.order}
									orderPriority={orderSettings?.[columnKey]?.orderPriority}
									hidden={isHiddenColumn(columnsMap[columnKey]!)}
									noResize={columnsMap[columnKey]?.fixedWidth || noResize}
									onFilterSet={() => onFilterSet?.(columnKey)}
									onFilterClear={() => onFilterClear?.(columnKey)}
									onOrderChanged={(o, b) => onOrderChanged?.(columnKey, o, b)}
									onResize={(newSize) => onColumnResize?.(columnKey, newSize)}
								/>
							))}

							{/* Ghost column header. Used to correct column resize. */}
							<th className="absolute" />

							{displayOptionsPanel && (
								<th className="w-0 p-0 align-top">
									<div className="absolute right-0 flex h-10 w-9 items-center justify-center bg">
										{!hideSettings && (
											<span
												role="presentation"
												data-testid={`${testid}-settings-menu-button`}
												onClick={() => onSettingsMenu?.()}
												className="w-5 cursor-pointer"
											>
												<IconSettings />
											</span>
										)}
									</div>
								</th>
							)}
						</tr>
					</thead>
					<tbody>
						{data.map((row, i) => (
							<tr
								onContextMenu={(e) => onRowContextMenu?.(e, row) || e.preventDefault()}
								onDoubleClick={(e) => onRowDoubleClick?.(e, row)}
								className="group h-9 even:bg-grayscale-100 hover:bg-grayscale-200"
								key={`${row[rowKey]}`}
							>
								{selectable && selectable.selectedRows && (
									<td className="p-2">
										<CheckBox
											testid={`${testid}-table-checkbox-${row[rowKey]}`}
											checked={selectable.selectedRows.has(row[rowKey] as string | number)}
											onChange={(e) => onSelectedRowChange?.(new Set([row[rowKey] as string | number]), e.target.checked)}
										/>
									</td>
								)}

								{columnsOrder?.map((colKey) => (
									<td
										key={colKey.toString()}
										className={`max-w-0 truncate px-3 py-2 text-left 
											${isHiddenColumn(columnsMap[colKey]!) && 'hidden'}`}
									>
										{columnsMap[colKey]!.template ? columnsMap[colKey]!.template?.(row) : `${row[colKey] ?? ''}`}
									</td>
								))}

								{/* Ghost column. Used to correct column resize. */}
								<td className="absolute" />

								{displayOptionsPanel && (
									<td className="p-0 align-top">
										<div
											className={`absolute right-0 flex h-10 w-9 items-center justify-center group-hover:bg-grayscale-200
												${i % 2 === 1 ? 'bg-grayscale-100' : 'bg'}`}
										>
											{!hideRowOptions && (
												<span
													onClick={(e) => onRowContextMenu?.(e, row)}
													onContextMenu={(e) => e.stopPropagation()}
													role="presentation"
													className="h-9 w-5 cursor-pointer"
												>
													<IconDots />
												</span>
											)}
										</div>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

import { useDateTimePicker } from '@ipis-ui-kit/react-headless';

import { DayAndTimePickerPanel } from './DayAndTimePickerPanel';
import { MainPickerPanelBodyProps } from './MainPickerPanelBody.types';
import { MonthPickerPanel } from './MonthPickerPanel';
import { YearPickerPanel } from './YearPickerPanel';

export function MainPickerPanelBody(props: MainPickerPanelBodyProps): JSX.Element {
	const { testid } = props;

	const { stage } = useDateTimePicker();

	return (
		<div className="h-56 w-80">
			{stage === 'day' && <DayAndTimePickerPanel testid={testid} />}

			{stage === 'month' && <MonthPickerPanel testid={testid} />}

			{stage === 'year' && <YearPickerPanel testid={testid} />}
		</div>
	);
}

import { useState, MouseEventHandler, useRef } from 'react';

import { ModalProvider } from './useModal';
import { ModalProps, ModalState, ModalWidth } from './Modal.types';

import { ModalHeader } from './ModalHeader';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalOverlay } from './ModalOverlay';

import { calculateNewWindowPosition, getCurrentCursorPosition } from './windowDraggingComputation';
import { useKeyboard } from '../../hooks/useKeyboard';
import { Portal } from '../portal/Portal';

const widthClassDictionary: { [key in ModalWidth]: string } = {
	tiny: 'w-80',
	small: 'w-124',
	medium: 'w-186',
	large: 'w-248',
};

function Modal({ onClose, onSubmit, children, width, testid }: ModalProps): JSX.Element {
	useKeyboard('enter', onSubmit);
	useKeyboard('escape', onClose);

	const modalRootRef = useRef<HTMLDivElement>(null);

	const [header, body, footer, overlay] = children;
	const [dragState, setDragState] = useState<ModalState>({ cursorPosition: { top: 0, left: 0 } });

	const onMouseMove = (e: MouseEvent) => {
		e.preventDefault();

		setDragState((prev) => ({
			cursorPosition: getCurrentCursorPosition(e),
			elementPosition: calculateNewWindowPosition(e, prev.cursorPosition, modalRootRef.current!),
		}));
	};

	const onMouseUp = () => {
		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp);
	};

	const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
		e.preventDefault();

		setDragState((prev) => ({ ...prev, cursorPosition: getCurrentCursorPosition(e) }));

		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp);
	};

	return (
		<Portal>
			<div data-testid={`${testid}-modal-container`}>
				{overlay}

				<div className="fixed inset-0">
					<div className="inset-0 flex min-h-full items-center justify-center">
						<div
							ref={modalRootRef}
							data-testid={`${testid}-dialog`}
							style={dragState.elementPosition}
							className={`${widthClassDictionary[width]} absolute rounded-2xl bg shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]`}
						>
							<ModalProvider onClose={onClose} onMouseDown={onMouseDown}>
								{header}
								{body}
								{footer}
							</ModalProvider>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Overlay = ModalOverlay;

export { Modal };

import { useMemo, useState } from 'react';
import { TableRow } from '@ipis-ui-kit/table-types';
import { TableSettingsPanel } from './settings/TableSettingsPanel';
import { CustomizableTableProps } from './CustomizableTable.types';
import { TableRoot } from './TableRoot';

export function CustomizableTable<TRow extends TableRow>(props: CustomizableTableProps<TRow>): JSX.Element {
	const { testid, data, columns, order, hiddenColumns, columnOrder, columnSizes, filter, hideRowOptions, hideSettings, noResize, selectable }
	 = props; // prettier-ignore
	const { onRowContextMenu, onRowDoubleClick, onOrderChanged, onFilterSet, onFilterClear, onHiddenColumnsSet, onColumnsOrderChange, onColumnResize }
	 = props; // prettier-ignore
	const { onAllRowsSelect, onSelectedRowChange, onSelectedRowsClear } = props;

	const [settingsWindowVisible, setSettingsWindowVisible] = useState<boolean>(false);

	const columnsInfo = useMemo(
		() =>
			columns
				.filter((c) => !c.ghost)
				.reduce((acc, cur) => ({ ...acc, [cur.columnKey]: { hidden: cur.hidden, static: cur.static, title: cur.title } }), {}),
		[columns],
	);

	return (
		<>
			<TableRoot
				testid={testid}
				data={data ?? []}
				columns={columns}
				orderSettings={order}
				filter={filter}
				columnsOrder={columnOrder}
				hiddenColumns={hiddenColumns}
				columnSizes={columnSizes}
				hideRowOptions={hideRowOptions}
				noResize={noResize}
				selectable={selectable}
				onRowDoubleClick={onRowDoubleClick}
				onRowContextMenu={onRowContextMenu}
				onOrderChanged={onOrderChanged}
				onFilterSet={onFilterSet}
				onFilterClear={onFilterClear}
				hideSettings={hideSettings}
				onSettingsMenu={() => setSettingsWindowVisible(!settingsWindowVisible)}
				onAllRowsSelect={onAllRowsSelect}
				onSelectedRowChange={onSelectedRowChange}
				onSelectedRowsClear={onSelectedRowsClear}
				onColumnResize={onColumnResize}
			/>

			<TableSettingsPanel
				testid="table-settings-panel"
				show={settingsWindowVisible}
				onClose={() => setSettingsWindowVisible(false)}
				columns={columnsInfo}
				columnsOrder={columnOrder}
				hiddenColumns={hiddenColumns}
				onSubmit={(newOrder, newHidden) => {
					onColumnsOrderChange?.(newOrder);
					onHiddenColumnsSet?.(newHidden);
				}}
			/>
		</>
	);
}

import React from 'react';
import { OptionMenuProps, OptionMenuWidth, OptionMenuAnimationDirection } from './OptionMenu.types';

const widthDictionary: { [key in OptionMenuWidth]: string } = {
	full: 'w-full',
	small: 'w-32',
	medium: 'w-48',
	large: 'w-72',
	auto: 'w-auto',
};

const animationDirectionDictionary: { [key in OptionMenuAnimationDirection]: string } = {
	top: 'origin-top',
	center: 'origin-center',
	bottom: 'origin-bottom',
};

export const OptionMenu = React.forwardRef<HTMLDivElement, OptionMenuProps>((props: OptionMenuProps, ref): JSX.Element => {
	// eslint-disable-next-line react/prop-types
	const { children, style, width, expanded, animationDirection, testid } = props;
	return (
		<div
			data-testid={`${testid}-option-menu`}
			ref={ref}
			style={style}
			className={`absolute transform overflow-hidden transition-transform
					${widthDictionary[width ?? 'auto']}
					${expanded ? 'scale-y-100' : 'scale-y-0'}
					${animationDirectionDictionary[animationDirection ?? 'bottom']}`}
		>
			<div className="border-2 border-grayscale-300 bg text-left font-light">{children}</div>
		</div>
	);
});

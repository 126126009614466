import { useMemo } from 'react';
import { useQueries } from 'react-query';
import axios, { AxiosError } from 'axios';
import { TableColumn } from '@ipis-ui-kit/table-types';
import { useODataTable } from '@ipis-ui-kit/odata-table';
import { Button, LoadingContainer, ODataTable, StackPanel } from '../../components';
import { IconDownload, IconFilterOff, IconShare } from '../../components/icons';
import { useTitle } from '../../hooks/useTitle';
import { formatDatePeriod } from '../../lib/formatters/datePeriodFormatter';
import { formatUtcDateTime } from '../../lib/formatters/dateFormatters';
import { formatFileSize } from '../../lib/formatters/fileSizeFormatter';
import { useAlerts } from '../../app/alerts';
import { getUnexpectedErrorAlert } from '../../lib/alerts/alerts';
import { Report } from '../../api/contract';
import { ReportsTableRowOptions } from './components/ReportsTableRowOptions';
import { useReportsActions } from './useReportsActions';
import { useReportsSelectors } from './useReportsSelectors';

import * as stringFilters from '../../components/table/odata/filters/oDataTableStringFilters';
import * as dateFilters from '../../components/table/odata/filters/oDataTableDateFilters';
import * as dateTimeFilters from '../../components/table/odata/filters/oDataTableDateTimeFilters';
import * as selectFilters from '../../components/table/odata/filters/oDataTableSelectFilters';
import { Forbidden } from '../Errors/Forbidden';
import { useError } from '../../app/errors/ErrorProvider';

function getColumns(
	onDownloadReport: (uids: string) => void,
	onShareReport: (uids: string) => void,
	partnersDict: Record<string, string>,
	resellersDict: Record<string, string>,
	reportTypesDict: Record<string, string>,
): TableColumn<Report>[] {
	return [
		{ columnKey: 'country', title: 'Country', filters: stringFilters.all({ caseInsensitive: true }) },
		{ columnKey: 'partnerCode', title: 'Partner', filters: selectFilters.all(partnersDict), width: 'small' },
		{ columnKey: 'resellerCode', title: 'Reseller', filters: selectFilters.all(resellersDict), width: 'small' },
		{ columnKey: 'account', title: 'Account', filters: stringFilters.all({ caseInsensitive: true }) },
		{ columnKey: 'reportTypeName', title: 'Report', filters: selectFilters.all(reportTypesDict) },
		{
			columnKey: 'beginTimestamp',
			title: 'Reporting Period',
			filters: dateFilters.all(),
			width: 'small',
			template: (r) => formatDatePeriod(new Date(r.beginTimestamp), new Date(r.endTimestamp)),
		},
		{ columnKey: 'timezone', title: 'Time Zone', filters: stringFilters.all({ caseInsensitive: true }), hidden: true },
		{
			columnKey: 'buildTimestamp',
			title: 'Build Date (UTC)',
			filters: dateTimeFilters.all(),
			width: 'small',
			order: 'descending',
			template: (r) => formatUtcDateTime(new Date(r.buildTimestamp!)),
		},
		{
			columnKey: 'fileSize',
			title: 'File Size',
			width: 'small',
			template: (r) => formatFileSize(r.fileSize!),
		},
		{
			columnKey: 'uid',
			isRowKey: true,
			static: true,
			fixedWidth: true,
			order: 'disabled',
			width: '4.5rem',
			template: (r) => (
				<ReportsTableRowOptions
					reportUid={r.uid}
					testid="reports"
					downloadReport={() => onDownloadReport(r.uid)}
					shareReport={() => onShareReport(r.uid)}
				/>
			),
		},
		{ columnKey: 'endTimestamp', ghost: true },
	];
}

export function Reports(): JSX.Element {
	useTitle('Reports');

	const { addAlert, clearAlerts } = useAlerts();
	const { setOverlappingError } = useError();
	const actions = useReportsActions();

	const results = useQueries([
		{
			queryKey: ['reports-partners'],
			queryFn: () =>
				axios.get<{ partnerCode: string }[]>('/api/oData/Reports?$apply=groupby((partnerCode))').then((res) => res?.data),
			onError(error: unknown) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response?.status === 403) {
					setOverlappingError('article', { fallbackComponent: () => <Forbidden /> });
				}
			},
		},
		{
			queryKey: ['reports-resellers'],
			queryFn: () =>
				axios.get<{ resellerCode: string }[]>('/api/oData/Reports?$apply=groupby((resellerCode))').then((res) => res?.data),
			onError(error: unknown) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response?.status === 403) {
					setOverlappingError('article', { fallbackComponent: () => <Forbidden /> });
				}
			},
		},
		{
			queryKey: ['reports-report-types'],
			queryFn: () =>
				axios.get<{ reportTypeName: string }[]>('/api/oData/Reports?$apply=groupby((reportTypeName))').then((res) => res?.data),
			onError(error: unknown) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response?.status === 403) {
					setOverlappingError('article', { fallbackComponent: () => <Forbidden /> });
				}
			},
		},
	]);

	const [partners, resellers, reportTypes] = results;
	const { partnerRecords, resellerRecords, reportTypeRecords } = useReportsSelectors(
		partners.data,
		resellers.data,
		reportTypes.data,
	);

	const columns = useMemo(
		() => getColumns(actions.onReportDownload, actions.onReportShare, partnerRecords, resellerRecords, reportTypeRecords),
		// causes invalid refetching
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[partnerRecords, resellerRecords, reportTypeRecords],
	);

	const isLoading = useMemo(() => results.some((x) => x.isLoading), [results]);
	const isError = useMemo(() => results.some((x) => x.isError), [results]);

	const table = useODataTable<Report>('/api/odata/reports', columns, {
		noAutoRefresh: true,
		pageSize: 50,
		enabled: !isLoading && !isError,
		selectable: { maxSelectableRows: 10000 },
		onSuccess: clearAlerts,
		onError: () => addAlert(getUnexpectedErrorAlert()),
	});
	const tableIsFiltered = useMemo(() => !!table && Object.values(table.filters).some((f) => f?.length), [table]);
	const rowsAreSelected = useMemo(() => !table || table.selectedRows.size === 0, [table]);

	return (
		<LoadingContainer testid="reports" noContentWhileLoading noLoaderOverlay isLoading={isLoading}>
			{!isError && (
				<>
					<StackPanel testid="reports-stack-panel" direction="horizontal">
						<Button
							testid="reports-download"
							icon={<IconDownload />}
							isLoading={actions.isMultipleReportsDownloading}
							disabled={rowsAreSelected}
							onClick={() => actions.onReportsDownload([...table.selectedRows] as string[])}
						>
							Download
						</Button>
						<Button
							testid="reports-share"
							icon={<IconShare />}
							isLoading={actions.isMultipleReportsSharing}
							disabled={rowsAreSelected}
							onClick={() => actions.onReportsShare([...table.selectedRows] as string[])}
						>
							Share
						</Button>
						<Button
							testid="reports-clear-filters"
							icon={<IconFilterOff />}
							disabled={!tableIsFiltered}
							onClick={() => {
								table?.clearFilters();
								table?.clearSelectedRows();
							}}
						>
							Clear Filters
						</Button>
					</StackPanel>

					<br />

					<ODataTable testid="reports" hideRowOptions options={table.options} />
				</>
			)}
		</LoadingContainer>
	);
}

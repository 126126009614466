import { ScrollContainer } from '../scrollcontainer/ScrollContainer';
import { ModalBodyProps } from './ModalBody.types';

export function ModalBody({ children }: ModalBodyProps): JSX.Element {
	return (
		<div className="px-3">
			<ScrollContainer testid="modal-scroll" orientation="vertical" className="max-h-3/4-screen w-full px-3">
				{children}
			</ScrollContainer>
		</div>
	);
}

import { Menu } from '@headlessui/react';
import { HeaderMenuItemProps } from './HeaderMenuItem.types';

export function HeaderMenuItem(props: HeaderMenuItemProps): JSX.Element {
	const { testid, text, filled, onClick } = props;

	return (
		<Menu.Item>
			{({ active }) => (
				<div
					data-testid={`${testid}-header-menu-item`}
					className={`min-w-8 cursor-pointer px-4 py-3 text-sm
						${filled ? 'bg-primary text-opposite hover:bg-primary-highlight-dark' : 'bg text hover:bg-grayscale-100'} 
						${active && (filled ? 'bg-primary-highlight-dark' : 'bg-grayscale-100')}`}
					role="presentation"
					onClick={onClick}
				>
					{text}
				</div>
			)}
		</Menu.Item>
	);
}

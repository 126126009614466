import { memo } from 'react';
import { useMonth } from '@ipis-ui-kit/react-headless';

import { MonthProps } from './Month.types';

export const Month = memo((props: MonthProps) => {
	const { testid, date, monthLabel } = props;

	const { isCurrentMonth, isSelected, onSelect } = useMonth({ date });

	return (
		<button
			onClick={onSelect}
			type="button"
			tabIndex={0}
			data-testid={testid}
			className={`select-none rounded p-4 text-sm focus:outline-1 focus:outline
				${isSelected ? 'bg-primary text-opposite' : 'bg text'}
				${isCurrentMonth && !isSelected ? 'bg-grayscale-200 font-bold text' : ''}`}
		>
			{monthLabel}
		</button>
	);
});

import { StackPanelProps, StackPanelDirection } from './StackPanel.types';

const directionDictionary: { [key in StackPanelDirection]: string } = {
	horizontal: 'flex-row',
	vertical: 'flex-col',
};

export function StackPanel(props: StackPanelProps): JSX.Element {
	const { children, testid, direction } = props;

	return (
		<div data-testid={`${testid}-stack-panel`} className={`flex ${directionDictionary[direction]} gap-2`}>
			{children}
		</div>
	);
}

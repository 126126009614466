import { ModalFooterItemsPosition, ModalFooterProps } from './ModalFooter.types';

const positionClassDictionary: { [key in ModalFooterItemsPosition]: string } = {
	left: 'justify-start',
	center: 'justify-center',
	right: 'justify-end',
};

export function ModalFooter({ itemsPosition, children }: ModalFooterProps): JSX.Element {
	return <div className={`flex w-full flex-row rounded-b-2xl p-6 ${positionClassDictionary[itemsPosition]}`}>{children}</div>;
}

import { TitleProps } from './Title.types';

const levelDictionary: { [key in TitleProps['level']]: string } = {
	h1: 'text-2xl',
	h2: 'text-xl',
	h3: 'text-lg',
	h4: 'text-base',
};

export function Title({ level, testid, children }: TitleProps): JSX.Element {
	return (
		<div data-testid={`${testid}-title`} className={`${levelDictionary[level]} mb-5 font-medium`}>
			{children}
		</div>
	);
}

import { autoUpdate, ReferenceType, size } from '@floating-ui/react-dom';

export const fullSize = () =>
	size({
		apply({ elements }) {
			Object.assign(elements.floating.style, {
				width: `${elements.reference.getBoundingClientRect().width}px`,
			});
		},
	});

export const whileElementsMounted = (reference: ReferenceType, floating: HTMLElement, update: () => void) => {
	const autoUpdateCleanup = autoUpdate(reference, floating, update, {
		animationFrame: true,
		ancestorResize: true,
	});

	document.addEventListener('scroll', update);

	return () => {
		document.removeEventListener('scroll', update);
		autoUpdateCleanup();
	};
};

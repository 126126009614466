import { useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { TableColumnOrder, TableHiddenColumns, TableRow } from '@ipis-ui-kit/table-types';
import { TableSettingsColumns } from './TableSettingsPanel.types';

export const useTableSettings = <TRow extends TableRow>(
	columnsOrder: TableColumnOrder<TRow>,
	columns: TableSettingsColumns<TRow>,
	show: boolean,
	hiddenColumns?: TableHiddenColumns<TRow>,
) => {
	const [order, setOrder] = useState<(keyof TRow)[]>(columnsOrder);
	const [hidden, setHidden] = useState<Set<keyof TRow>>(() => new Set<keyof TRow>(hiddenColumns));

	useEffect(() => {
		if (show) {
			setOrder(columnsOrder);
			setHidden(new Set<keyof TRow>(hiddenColumns));
		}
	}, [show, columnsOrder, hiddenColumns]);

	const staticColumns = useMemo(() => Object.keys(columns).filter((x) => columns[x]?.static), [columns]);
	const originColumnsIndexes = useMemo<{ [key: string]: number }>(
		() =>
			Object.keys(columns)
				.map((k, i) => ({ columnKey: k, index: i }))
				.reduce((a, { columnKey, index }) => ({ ...a, [columnKey]: index }), {}),
		[columns],
	);

	const isHideLockedFor = (columnKey: keyof TRow) =>
		hidden.size + staticColumns.length === Object.keys(columns).length - 1 && !hidden.has(columnKey);

	const toggleHide = (columnKey: keyof TRow) => {
		setHidden((prev) => new Set(prev.has(columnKey) ? [...prev].filter((x) => x !== columnKey) : [...prev, columnKey]));
	};

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		const newOrder = Array.from(order).filter((col) => !columns[col]?.static);
		const [removed] = newOrder.splice(result.source.index, 1);
		newOrder.splice(result.destination.index, 0, removed);
		staticColumns.forEach((col) => newOrder.splice(originColumnsIndexes[col], 0, col));
		setOrder(newOrder);
	};

	return { order, hidden, isHideLockedFor, toggleHide, onDragEnd };
};

import { usePickerDays } from '@ipis-ui-kit/react-headless';
import { Day } from '../items/Day';
import { DayPickerPanelProps } from './DayPickerPanel.types';

export function DayPickerPanel(props: DayPickerPanelProps): JSX.Element {
	const { testid } = props;

	const { days, weekdayLabels } = usePickerDays();

	const getKey = (index: number) => index.toString();

	return (
		<div>
			<div className="grid grid-cols-[repeat(7,_1fr)] justify-center text-xs font-light">
				{weekdayLabels.map((label) => (
					<div className="select-none text-center text-xs text-grayscale-400" key={label}>
						{label}
					</div>
				))}
			</div>

			<div className="mr-1 grid grid-cols-[repeat(7,_1fr)] grid-rows-6 justify-center gap-0.5">
				{days.map((day, index) =>
					typeof day === 'object' ? (
						<Day testid={`${testid}-${day.dayLabel}`} date={day.date} key={day.date.toString()} dayLabel={day.dayLabel} />
					) : (
						<div key={getKey(index)} />
					),
				)}
			</div>
		</div>
	);
}

import { PropsWithChildren } from 'react';
import { AlertToast, StackPanel } from '../../components';
import { useAlerts } from './AlertProvider';

export function AlertBoundary({ children }: PropsWithChildren<{}>): JSX.Element {
	const { alerts, removeAlert } = useAlerts();

	return (
		<>
			<StackPanel testid="alert-toast" direction="vertical">
				{alerts.map((a, i) => (
					<AlertToast
						key={a.message}
						testid={`alert-toast-${i}`}
						type={a.type}
						message={a.message}
						onClose={() => removeAlert(a.message, a.type)}
					/>
				))}
			</StackPanel>

			{children}
		</>
	);
}

import { stringExpressions, ODataTableFilter } from '@ipis-ui-kit/odata-table';
import { stringInput } from '../../ui/filtration/TableFilterModalInputs';
import { TableStringFilterOptions } from '../../ui/filtration/TableFilter.types';

export const eq = (options?: TableStringFilterOptions): ODataTableFilter<string> => ({
	title: 'equals',
	apply: stringExpressions.equals(options?.caseInsensitive),
	input: stringInput(),
});

export const ne = (options?: TableStringFilterOptions): ODataTableFilter<string> => ({
	title: 'does not equal',
	apply: stringExpressions.notEquals(options?.caseInsensitive),
	input: stringInput(),
});

export const startsWith = (options?: TableStringFilterOptions): ODataTableFilter<string> => ({
	title: 'starts with',
	apply: stringExpressions.startsWith(options?.caseInsensitive),
	input: stringInput(),
});

export const endsWith = (options?: TableStringFilterOptions): ODataTableFilter<string> => ({
	title: 'ends with',
	apply: stringExpressions.endsWith(options?.caseInsensitive),
	input: stringInput(),
});

export const contains = (options?: TableStringFilterOptions): ODataTableFilter<string> => ({
	title: 'contains',
	apply: stringExpressions.contains(options?.caseInsensitive),
	input: stringInput(),
});

const filters = [eq, ne, startsWith, endsWith, contains];

export const all = (options?: TableStringFilterOptions) => filters.map((filter) => filter(options));

import { Link } from '../../link/Link';
import { FooterProps } from './Footer.types';

export function Footer({ testid }: FooterProps): JSX.Element {
	return (
		<footer
			data-testid={`${testid}-footer`}
			className="flex h-16 w-full flex-shrink-0 flex-grow-0 bg font-light shadow-[0_-1px_4px_0_rgba(92,133,150,0.24)]"
		>
			<div className="m-auto mx-auto flex w-full items-center justify-between px-9 text-xs text-grayscale-600">
				<span className="overflow-hidden whitespace-nowrap">© {new Date().getFullYear()} Kaspersky. All rights reserved.</span>
				<Link testid={testid} target="_blank" href="https://www.kaspersky.com/web-privacy-policy">
					Privacy Policy
				</Link>
			</div>
		</footer>
	);
}

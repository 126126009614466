/* eslint-disable react/jsx-props-no-spreading */
import { ODataTable as ODataWrapper } from '@ipis-ui-kit/odata-table';
import { TableProps, TableRow } from '@ipis-ui-kit/table-types';
import { useCallback } from 'react';
import { ODataTableProps } from './ODataTable.types';
import { Table } from '../ui/Table';

export function ODataTable<TRow extends TableRow>(props: ODataTableProps<TRow>): JSX.Element {
	const { testid, ...other } = props;

	const asFunc = useCallback((tableProps: TableProps<TRow>) => <Table testid={testid} {...tableProps} />, [testid]);

	return <ODataWrapper {...other} as={asFunc} />;
}

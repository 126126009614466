import { LoadingContainer } from '../../components/loading/LoadingContainer';
import { Layout } from '../../pages/Layout/Layout';
import { useError } from '../errors/ErrorProvider';
import { useUserIdentity } from '../security/UserIdentityProvider';

export function App(): JSX.Element {
	const { errorLevel } = useError();
	const { isLoading } = useUserIdentity();

	const applicationIsReady = !isLoading || !!errorLevel;

	return (
		<div className="h-0 min-h-screen">
			<LoadingContainer testid="app" isLoading={!applicationIsReady} noContentWhileLoading noLoaderOverlay>
				<Layout />
			</LoadingContainer>
		</div>
	);
}

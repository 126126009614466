import { useDateTimePicker } from '@ipis-ui-kit/react-headless';

import { TimePanel } from './TimePanel';
import { DayAndTimePickerPanelProps } from './DayAndTimePickerPanel.types';
import { DayPickerPanel } from './DayPickerPanel';

export function DayAndTimePickerPanel(props: DayAndTimePickerPanelProps): JSX.Element {
	const { testid } = props;

	const { displayOptions } = useDateTimePicker();

	return (
		<div className="flex h-full max-h-min w-full items-center">
			<div className="flex-grow">
				<DayPickerPanel testid={`${testid}-month`} />
			</div>

			{displayOptions.timePickerEnabled && (
				<>
					<div className="h-5/6 border-r border-r-grayscale-200 py-1" />
					<TimePanel testid={`${testid}-time-panel`} />
				</>
			)}
		</div>
	);
}

import { ODataTableFilter, dateTimeExpressions } from '@ipis-ui-kit/odata-table';
import { TableDateFilterOptions } from '../../ui/filtration/TableFilter.types';
import { dateInput } from '../../ui/filtration/TableFilterModalInputs';

export const eq = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'equals',
	apply: dateTimeExpressions.equals('day'),
	input: dateInput(options),
});

export const ne = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'does not equal',
	apply: dateTimeExpressions.notEquals('day'),
	input: dateInput(options),
});

export const gt = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'greater than',
	apply: dateTimeExpressions.greaterThan('day'),
	input: dateInput(options),
});

export const lt = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'less than',
	apply: dateTimeExpressions.lessThan('day'),
	input: dateInput(options),
});

export const ge = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'greater or equal to',
	apply: dateTimeExpressions.greaterOrEqualTo('day'),
	input: dateInput(options),
});

export const le = (options?: TableDateFilterOptions): ODataTableFilter<Date> => ({
	title: 'less or equal to',
	apply: dateTimeExpressions.lessOrEqualTo('day'),
	input: dateInput(options),
});

const filters = [eq, ne, gt, ge, lt, le];

export const all = (options?: TableDateFilterOptions) => filters.map((filter) => filter(options));

import { useEffect } from 'react';

export const useKeyboard = (code: string, action: () => void) => {
	useEffect(() => {
		const performAction = (e: KeyboardEvent) => {
			if (e.key.toLowerCase() === code) {
				action();
			}
		};

		window.addEventListener('keydown', performAction);

		return () => {
			window.removeEventListener('keydown', performAction);
		};
	}, [action, code]);
};

/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '../../../text-field/TextField';
import { Select } from '../../../select/Select';
import { SelectItem } from '../../../select/Select.types';
import { TableDateFilterOptions, TableNumberFilterOptions } from './TableFilter.types';

export function numberInput(options?: TableNumberFilterOptions) {
	return (value: number | number[] | undefined, onValueChangedCallback: (newValue: number | number[]) => void) => {
		let currentValue = value;

		if (value === undefined) {
			currentValue = options?.min ?? 0;
			onValueChangedCallback(currentValue);
		}

		return (
			<TextField
				{...options}
				testid="number-filter"
				type="number"
				value={currentValue as number}
				onChange={(e) => onValueChangedCallback(Number(e.target.value))}
			/>
		);
	};
}

export function stringInput() {
	return (value: string | string[] | undefined, onValueChangedCallback: (newValue: string | string[]) => void) => {
		if (Array.isArray(value)) {
			throw new Error('Multiple values is not supported');
		}

		return (
			<TextField
				testid="string-filter"
				type="text"
				value={value ?? ''}
				onChange={(e) => onValueChangedCallback(e.target.value)}
			/>
		);
	};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function dateTimeInput(options?: TableDateFilterOptions) {
	return (value: Date | Date[] | undefined, onValueChangedCallback: (newValue?: Date | Date[]) => void) => {
		if (Array.isArray(value)) {
			throw new Error('Multiple values is not supported');
		}

		let currentValue = value;

		if (value === undefined) {
			currentValue = new Date();
			onValueChangedCallback(currentValue);
		}

		const toDisplayDate = (date: Date): string => date.toISOString().replace(/[.]\d+Z/, '');
		const fromDisplayDate = (date: string): Date => (Number.isNaN(Date.parse(date)) ? value! : new Date(`${date}Z`));

		return (
			<TextField
				testid="date-time-filter"
				type="datetime-local"
				step={1}
				value={toDisplayDate(currentValue!)}
				onChange={(e) => onValueChangedCallback(fromDisplayDate(e.target.value))}
			/>
		);
	};
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function dateInput(options?: TableDateFilterOptions) {
	return (value: Date | Date[] | undefined, onValueChangedCallback: (newValue?: Date | Date[]) => void) => {
		if (Array.isArray(value)) {
			throw new Error('Multiple values is not supported');
		}

		let currentValue = value;

		if (value === undefined) {
			currentValue = new Date();
			onValueChangedCallback(currentValue);
		}

		const toDisplayDate = (date: Date): string => date.toISOString().substring(0, 10);
		const fromDisplayDate = (date: string): Date =>
			Number.isNaN(Date.parse(`${date}T00:00:00Z`)) ? value! : new Date(`${date}T00:00:00Z`);

		return (
			<TextField
				testid="date-filter"
				type="date"
				value={toDisplayDate(currentValue!)}
				onChange={(e) => onValueChangedCallback(fromDisplayDate(e.target.value))}
			/>
		);
	};
}

export function selectInput(typeEnum: Record<string, string>) {
	return (value: string | string[] | undefined, onValueChangedCallback: (newValue?: string | string[]) => void) => {
		if (Object.keys(typeEnum).length === 0) {
			return <Select testid="select-filter" selectedId="" onChange={() => {}} items={[{ id: null!, value: 'All' }]} />;
		}

		let currentValue = value;
		if (value === undefined) {
			[currentValue] = Object.keys(typeEnum);
			onValueChangedCallback(currentValue);
		}

		return (
			<Select
				testid="select-filter"
				selectedId={currentValue!.toString()}
				onChange={(selected) => onValueChangedCallback(selected.value)}
				items={Object.entries(typeEnum).map(([k, v]) => ({ id: k, value: v } as SelectItem))}
			/>
		);
	};
}

import { StackPanel } from '../../../components';
import { IconDownload, IconShare } from '../../../components/icons';
import { ReportsTableRowOptionsProps } from './ReportsTableRowOptions.types';

export function ReportsTableRowOptions(props: ReportsTableRowOptionsProps): JSX.Element {
	const { testid, reportUid, downloadReport, shareReport } = props;

	return (
		<StackPanel testid={testid} direction="horizontal">
			<button type="button" data-testid={`${testid}-button-download-${reportUid}`} onClick={downloadReport}>
				<IconDownload />
			</button>
			<button type="button" data-testid={`${testid}-button-share-${reportUid}`} onClick={shareReport}>
				<IconShare />
			</button>
		</StackPanel>
	);
}

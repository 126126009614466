import { Error } from '../components/error/Error';
import { IconWarning } from '../../components/icons/IconWarning';

export function Unauthorized(): JSX.Element {
	return (
		<Error
			title="You are not authenticated - either not authenticated at all or authenticated incorrectly"
			description="Please reauthenticate and try again."
			icon={<IconWarning />}
			testid="unauthorized-error"
		/>
	);
}

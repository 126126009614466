import { LinkProps } from './Link.types';

export function Link(props: LinkProps): JSX.Element {
	const { testid, children, href, target, onClick } = props;

	return (
		<a
			data-testid={`${testid}-link`}
			className="text-primary hover:text-primary-highlight"
			href={href}
			target={target}
			onClick={onClick}
		>
			{children}
		</a>
	);
}

/* eslint-disable no-bitwise */
import { useMemo, useRef } from 'react';
import { IconCheck, IconCheckIndeterminate } from '../icons';
import { CheckBoxProps } from './CheckBox.types';

enum CheckBoxStyleFlags {
	None = 0,
	Disabled = 1 << 1,
	Error = 1 << 2,
	Checked = 1 << 3,
	Unchecked = 1 << 4,
	Indeterminate = 1 << 5,
}

const checkboxStyle = {
	[CheckBoxStyleFlags.Checked]: 'border-primary bg-primary hover:border-primary-highlight hover:bg-primary-highlight',
	[CheckBoxStyleFlags.Unchecked]: 'border-grayscale-300 hover:bg-grayscale-200',
	[CheckBoxStyleFlags.Indeterminate]: 'border-grayscale-300 text-primary hover:text-primary-highlight',
	[CheckBoxStyleFlags.Checked | CheckBoxStyleFlags.Error]: 'border-danger bg-danger hover:bg-danger-highlight hover:border-danger-highlight', // prettier-ignore
	[CheckBoxStyleFlags.Unchecked | CheckBoxStyleFlags.Error]: 'border-danger hover:border-danger-highlight',
	[CheckBoxStyleFlags.Checked | CheckBoxStyleFlags.Disabled]: 'border-primary-disabled bg-primary-disabled',
	[CheckBoxStyleFlags.Unchecked | CheckBoxStyleFlags.Disabled]: 'bg-grayscale-100 border-grayscale-200',
};

export function CheckBox(props: CheckBoxProps): JSX.Element {
	const { testid, checked, children, disabled, error, helperText, onChange } = props;

	const inputChecked = useMemo(() => (checked === 'indeterminate' ? true : checked), [checked]);
	const inputRef = useRef<HTMLInputElement>(null);

	let styleProps = CheckBoxStyleFlags.None;
	styleProps |= checked === true ? CheckBoxStyleFlags.Checked : CheckBoxStyleFlags.None;
	styleProps |= checked === false ? CheckBoxStyleFlags.Unchecked : CheckBoxStyleFlags.None;
	styleProps |= checked === 'indeterminate' ? CheckBoxStyleFlags.Indeterminate : CheckBoxStyleFlags.None;
	styleProps |= error ? CheckBoxStyleFlags.Error : CheckBoxStyleFlags.None;
	styleProps |= disabled ? CheckBoxStyleFlags.Disabled : CheckBoxStyleFlags.None;

	if (error && disabled) {
		throw Error('Disabled checkbox can not be errored');
	}

	return (
		<div>
			<input
				type="checkbox"
				className="hidden"
				data-testid={`${testid}-checkbox`}
				ref={inputRef}
				checked={inputChecked}
				disabled={disabled}
				readOnly={!onChange}
				onChange={onChange}
			/>

			<div
				data-testid={`${testid}-checkbox-inner`}
				className={`flex items-start gap-3 outline-none
					${disabled ? 'text-grayscale-300' : 'text-grayscale-600'}`}
			>
				<div
					data-testid={`${testid}-checkbox-main`}
					className={`h-6 w-6 flex-shrink-0 flex-grow-0 cursor-pointer rounded border p-0.5 text-opposite transition-all ${checkboxStyle[styleProps]}`}
					role="checkbox"
					aria-checked={inputChecked}
					tabIndex={-1}
					onKeyUp={(e) => e.code === 'Space' && inputRef.current?.click()}
					onClick={() => inputRef.current?.click()}
				>
					{checked === 'indeterminate' && <IconCheckIndeterminate />}
					{checked === true && <IconCheck />}
				</div>

				{children && <div className="self-center font-light">{children}</div>}
			</div>
			{error && helperText && <div className="text-xs text-danger">{helperText}</div>}
		</div>
	);
}

import { createContext, PropsWithChildren, useContext } from 'react';
import { ModalContextProps } from './Modal.types';

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

const ModalProvider = ({ children, ...events }: PropsWithChildren<ModalContextProps>): JSX.Element => (
	<ModalContext.Provider value={events}>{children}</ModalContext.Provider>
);

const useModal = (): ModalContextProps => {
	const modalContextData = useContext(ModalContext);

	if (!modalContextData) {
		throw new Error('useModal must be used only inside ModalProvider');
	}

	return modalContextData;
};

export { ModalProvider, useModal };

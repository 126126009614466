import { memo } from 'react';
import { useYear } from '@ipis-ui-kit/react-headless';

import { YearProps } from './Year.types';

export const Year = memo((props: YearProps) => {
	const { testid, date, yearLabel } = props;

	const { isCurrentYear, isSelected, onSelect } = useYear({ date });

	return (
		<button
			onClick={onSelect}
			type="button"
			tabIndex={0}
			data-testid={testid}
			className={`select-none rounded p-3 text-sm focus:outline-1 focus:outline
				${isSelected ? 'bg-primary text-opposite' : 'bg text'}
				${isCurrentYear && !isSelected ? 'bg-grayscale-200 font-bold text' : ''}`}
		>
			{yearLabel}
		</button>
	);
});

export function IconSortOff() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M3 13H15V11H3M3 6V8H21V6M3 18H9V16H3V18M22.54 16.88L20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.46L22.54 16.88"
			/>
		</svg>
	);
}

import { PaginationItemProps } from './PaginationItem.types';

export function PaginationItem(props: PaginationItemProps): JSX.Element {
	const { disabled, highlighted, isIcon, onClick, testid, children } = props;

	return (
		<button
			data-testid={`${testid}-pagination-item`}
			type="button"
			disabled={disabled}
			onClick={onClick}
			className="group flex items-center justify-center rounded font-normal disabled:pointer-events-none disabled:select-none disabled:text-grayscale-300"
		>
			{isIcon ? (
				<div className="group:disabled:text-grayscale-100 w-8 text-grayscale-300 transition-all hover:text-grayscale-400 group-disabled:text-grayscale-200">
					{children}
				</div>
			) : (
				<div
					className={`h-8 min-w-8 rounded p-1 transition-all ${
						highlighted
							? 'hover:bg-contrast bg-primary text-opposite hover:bg-primary-highlight'
							: 'bg-grayscale-200 hover:bg-grayscale-300'
					}`}
				>
					{children}
				</div>
			)}
		</button>
	);
}

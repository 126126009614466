import { MouseEvent } from 'react';
import { IconSpinner } from '../icons/IconSpinner';
import { ButtonProps, ButtonVariant } from './Button.types';

const shadows: { [key in ButtonVariant]: string } = {
	filled: 'shadow-primary hover:shadow-primary-highlight',
	outlined: 'shadow-primary hover:shadow-primary-highlight',
	dangerous: 'shadow-danger hover:shadow-danger-highlight',
};

const style: { [key in ButtonVariant]: string } = {
	filled: 'border-primary bg-primary text-opposite hover:border-primary-highlight hover:bg-primary-highlight active:border-primary-active active:bg-primary-active', // prettier-ignore
	outlined: 'border-primary bg text-primary hover:border-primary-highlight hover:text-primary-highlight active:border-primary-active active:text-primary-active', // prettier-ignore
	dangerous: 'border-danger bg-danger text-opposite hover:bg-danger-highlight hover:border-danger-highlight',
};

export function Button(props: ButtonProps): JSX.Element {
	const { testid, children, icon, variant, isLoading, shadow, width, disabled, onClick } = props;

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => (!isLoading && onClick ? onClick(event) : undefined);
	return (
		<button
			data-testid={`${testid}-button`}
			className={`relative flex select-none items-center overflow-hidden whitespace-nowrap rounded-md border-2 px-4 py-2.5 text-sm font-normal outline-none transition-colors active:shadow-none disabled:pointer-events-none disabled:border-grayscale-200 disabled:bg-grayscale-200 disabled:text-grayscale-300 disabled:shadow-none
				${style[variant ?? 'filled']}
				${width === 'full' && 'w-full'}
				${shadow ? shadows[variant ?? 'filled'] : ''}`}
			type="button"
			disabled={disabled}
			onClick={handleClick}
		>
			{icon && (
				<div data-testid={`${testid}-button-icon`} className={`mr-2 w-6 flex-shrink-0 flex-grow-0 ${isLoading && 'invisible'}`}>
					{icon}
				</div>
			)}
			<div data-testid={`${testid}-button-text`} className={`flex-auto ${isLoading && 'invisible'}`}>
				{children}
			</div>
			{isLoading && (
				<div className="absolute left-1/2 top-1/2 w-6 -translate-x-1/2 -translate-y-1/2">
					<IconSpinner />
				</div>
			)}
		</button>
	);
}

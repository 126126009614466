/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, CSSProperties } from 'react';
import { Popover } from '@headlessui/react';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';

import { Portal } from '../portal/Portal';
import { DateTimeInputProps } from './DateTimeInput.types';
import { useDateTimeInput } from './useDateTimeInput';
import { MainPickerPanel } from './components/panels/MainPickerPanel';
import { TextField } from '../text-field/TextField';
import { IconCalendar } from '../icons';

export function DateTimeInput(props: DateTimeInputProps): JSX.Element {
	const { testid, value, onChange, resetOnBlur, ...rest } = props;
	const { timeZone: propTimeZone, mode, monthFormat, timeFormat, timeIntervalStep, dateFormat, ...other } = rest;

	const dateTimeInput = useDateTimeInput({ value, resetOnBlur, dateFormat, timeZone: propTimeZone, onChange });

	const { x, y, reference, floating, strategy } = useFloating({
		middleware: [offset(10), flip(), shift()],
		whileElementsMounted: (r, f, update: () => void) => autoUpdate(r, f, update, { ancestorScroll: false }),
	});
	const position: CSSProperties = { top: y ?? 0, left: x ?? 0, position: strategy };

	return (
		<Popover>
			{({ close }) => (
				<>
					<div ref={reference}>
						<TextField
							{...other}
							testid={testid}
							value={dateTimeInput.inputValue}
							onChange={dateTimeInput.handleInputChange as (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void}
							onBlur={dateTimeInput.handleInputBlur}
							iconRight={
								<Popover.Button as="div" className="w-5">
									<IconCalendar />
								</Popover.Button>
							}
						/>
					</div>

					<Portal>
						<Popover.Panel ref={floating} focus style={position}>
							<MainPickerPanel
								date={dateTimeInput.dateTime}
								timeZone={dateTimeInput.timeZone}
								mode={mode}
								testid={testid}
								onChange={dateTimeInput.handlePickerPanelChange}
								monthFormat={monthFormat}
								timeFormat={timeFormat}
								timeIntervalStep={timeIntervalStep}
								onClose={close}
							/>
						</Popover.Panel>
					</Portal>
				</>
			)}
		</Popover>
	);
}

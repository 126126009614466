import { Error } from '../components/error/Error';
import { IconErrorNotFound } from '../../components/icons/IconErrorNotFound';

export function NotFound(): JSX.Element {
	return (
		<Error
			title="The page you are looking for doesn't exist or another error occurred"
			description="You may have entered an incorrect address or the page was deleted."
			icon={<IconErrorNotFound />}
			testid="not-found-error"
		/>
	);
}

import { useOutlet } from 'react-router-dom';
import { UserIdentity } from '../../api/contract/userIdentity';
import { EmailNotVerified } from '../../pages/Errors/EmailNotVerified';
import { NoAccountEmail } from '../../pages/Errors/NoAccountEmail';
import { useUserIdentity } from './UserIdentityProvider';

const getIdentityError = (identity: UserIdentity): JSX.Element | false => {
	if (!identity.email) return <NoAccountEmail />;
	if (!identity.isEmailVerified) return <EmailNotVerified />;

	return false;
};

export function UnauthorizedUsersBoundary() {
	const { identity, isUserError } = useUserIdentity();
	const outlet = useOutlet();
	const error = isUserError && getIdentityError(identity!);

	return error || outlet;
}

export function IconWarning() {
	return (
		<svg width="100%" viewBox="30 40 210 160" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M226 180H56.9996C55.9076 179.966 54.8736 179.501 54.1239 178.706C53.3742 177.911 52.9699 176.852 52.9996 175.76V171H230V175.76C230.027 176.851 229.621 177.909 228.872 178.703C228.123 179.497 227.091 179.963 226 180V180Z"
				fill="#1D1D1B"
			/>
			<path
				d="M86.8502 180H57.2002C56.1393 180 55.1219 179.579 54.3718 178.828C53.6216 178.078 53.2002 177.061 53.2002 176V171.5H90.8602V176C90.8602 176.526 90.7564 177.047 90.5548 177.533C90.3531 178.019 90.0576 178.46 89.6851 178.832C89.3126 179.204 88.8705 179.498 88.384 179.698C87.8975 179.899 87.3763 180.001 86.8502 180Z"
				fill="#375050"
			/>
			<path d="M111.15 171.35H107.75V173.35H111.15V171.35Z" fill="#A5BEBE" />
			<path d="M105.2 171.35H101.8V173.35H105.2V171.35Z" fill="#A5BEBE" />
			<path d="M99.2496 171.35H95.8496V173.35H99.2496V171.35Z" fill="#7EFF33" />
			<path
				d="M53.1996 171.5H189.2L173.42 82.0001C173.196 80.902 172.611 79.9108 171.756 79.1854C170.902 78.46 169.829 78.0426 168.71 78.0001H40.7096C40.2089 77.9823 39.7109 78.0802 39.2542 78.2861C38.7975 78.4919 38.3944 78.8003 38.0762 79.1872C37.758 79.5741 37.5332 80.0291 37.4193 80.5169C37.3055 81.0048 37.3055 81.5123 37.4196 82.0001L53.1996 171.5Z"
				fill="#375050"
			/>
			<path
				d="M56.1996 171.5H192.2L176.42 82.0001C176.196 80.902 175.611 79.9108 174.756 79.1854C173.902 78.46 172.829 78.0426 171.71 78.0001H43.7096C43.2089 77.9823 42.7109 78.0802 42.2542 78.2861C41.7975 78.4919 41.3944 78.8003 41.0762 79.1872C40.758 79.5741 40.5332 80.0291 40.4193 80.5169C40.3055 81.0048 40.3055 81.5123 40.4196 82.0001L56.1996 171.5Z"
				fill="#1D1D1B"
			/>
			<path d="M135.5 168.1H111.7L111.1 164.7H134.9L135.5 168.1Z" fill="#375050" />
			<path
				d="M60.3801 161.2H184.38C184.505 161.205 184.63 161.181 184.744 161.13C184.859 161.078 184.96 161.001 185.039 160.904C185.119 160.807 185.175 160.693 185.203 160.571C185.23 160.449 185.23 160.322 185.2 160.2L171.8 84.1999C171.744 83.925 171.597 83.6768 171.383 83.4955C171.169 83.3141 170.9 83.21 170.62 83.1999H46.6201C46.4948 83.1948 46.3701 83.2189 46.2558 83.2702C46.1414 83.3215 46.0405 83.3987 45.961 83.4956C45.8815 83.5926 45.8256 83.7066 45.7977 83.8288C45.7698 83.951 45.7706 84.078 45.8001 84.1999L59.2001 160.2C59.2577 160.474 59.4049 160.721 59.6186 160.902C59.8323 161.083 60.1002 161.188 60.3801 161.2V161.2Z"
				fill="url(#paint0_linear)"
			/>
			<path d="M95.9997 146.85H143.66L145.66 141.74L114.66 100.88H108.79L92.1797 141.74L95.9997 146.85Z" fill="white" />
			<path
				d="M120.69 140.3H116.69L116.09 136.9H120.09L120.69 140.3ZM119.5 133.5H115.5L112.5 116.5H116.5L119.5 133.5Z"
				fill="#1D1D1B"
			/>
			<path d="M220.22 99.87H165.91L150.22 115.56V57.25H220.22V99.87Z" fill="#1D1D1B" />
			<path
				d="M200.22 88.75H160.22V85.75H200.22V88.75ZM210.22 80.75H160.22V77.75H210.22V80.75ZM196.49 71.75H160.22V68.75H196.49V71.75Z"
				fill="url(#paint1_linear)"
			/>
			<defs>
				<linearGradient id="paint0_linear" x1="63.3601" y1="169.64" x2="140.38" y2="99.5599" gradientUnits="userSpaceOnUse">
					<stop stopColor="#FF8E40" /> <stop offset="1" stopColor="#FFE41B" />
				</linearGradient>
				<linearGradient id="paint1_linear" x1="160.89" y1="89.91" x2="203.15" y2="65.51" gradientUnits="userSpaceOnUse">
					<stop stopColor="#23D1AE" /> <stop offset="1" stopColor="#7EFF33" />
				</linearGradient>
			</defs>
		</svg>
	);
}

import { useMemo, useState, ChangeEvent, useCallback, useEffect } from 'react';
import { parse, format, isValid } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { UseDateTimeInputProps } from './useDateTimeInput.types';

const parseDateStrict = (value: string, dateFormat: string): Date | undefined => {
	const date = parse(value, dateFormat, 0);
	return isValid(date) && value === format(date, dateFormat) ? date : undefined;
};

const fromStringValueToDate = (value: string, dateFormat: string, timeZone: string): Date | null | undefined => {
	if (value === '') return null;
	const date = parseDateStrict(value, dateFormat);
	if (date === undefined) return undefined;
	return zonedTimeToUtc(date, timeZone);
};

const fromDateToStringValue = (date: Date | null | undefined, dateFormat: string, timeZone: string): string => {
	if (!date) return '';
	const zonedDate = utcToZonedTime(date, timeZone);
	return format(zonedDate, dateFormat);
};

export const useDateTimeInput = (props: UseDateTimeInputProps) => {
	const { value, resetOnBlur, dateFormat, timeZone: propTimeZone, onChange } = props;

	const timeZone = useMemo(() => propTimeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone, [propTimeZone]);
	const dateTime = useMemo(() => (value ? utcToZonedTime(value, timeZone) : null), [timeZone, value]);

	const [inputValue, setInputValue] = useState<string>(() => fromDateToStringValue(value, dateFormat, timeZone));

	useEffect(() => {
		if (value) {
			setInputValue(fromDateToStringValue(value, dateFormat, timeZone));
		} else if (value === null) setInputValue('');
	}, [dateFormat, timeZone, value]);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setInputValue(e.target.value);
			onChange(fromStringValueToDate(e.target.value, dateFormat, timeZone));
		},
		[dateFormat, onChange, timeZone],
	);

	const handleDateChange = useCallback(
		(date: Date | null | undefined) => {
			setInputValue(fromDateToStringValue(date, dateFormat, timeZone));
			onChange(date);
		},
		[dateFormat, onChange, timeZone],
	);

	const handlePickerPanelChange = useCallback(
		(date: Date | null) => handleDateChange(date ? zonedTimeToUtc(date, timeZone) : null),
		[handleDateChange, timeZone],
	);

	const handleInputBlur = useCallback(() => resetOnBlur && handleDateChange(value), [handleDateChange, resetOnBlur, value]);

	return { timeZone, inputValue, dateTime, handleInputChange, handlePickerPanelChange, handleInputBlur };
};

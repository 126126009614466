import { usePickerNavigation } from '@ipis-ui-kit/react-headless';

import { IconChevron } from '../../../icons';
import { MainPickerPanelHeaderProps } from './MainPickerPanelHeader.types';

export function MainPickerPanelHeader({ testid }: MainPickerPanelHeaderProps): JSX.Element {
	const { label, hasPreviousStage, setPreviousStage, toPreviousUnit, toNextUnit } = usePickerNavigation();

	return (
		<div
			className="flex w-full items-center justify-between border-b border-b-grayscale-200 px-3 py-3"
			data-testid={`${testid}-picker-header`}
		>
			<span role="presentation" title="Previous" className="mx-1 w-4 flex-shrink-0 cursor-pointer" onClick={toPreviousUnit}>
				<IconChevron />
			</span>
			<div className="flex w-32  flex-grow select-none justify-center text-center text-sm">
				<strong>{label}</strong>
				{hasPreviousStage && (
					<button className="ml-1 w-4 -rotate-90 rounded outline-1 focus:outline" type="button" onClick={setPreviousStage}>
						<IconChevron />
					</button>
				)}
			</div>
			<span role="presentation" title="Next" className="mx-1 w-4 flex-shrink-0 rotate-180 cursor-pointer" onClick={toNextUnit}>
				<IconChevron />
			</span>
		</div>
	);
}

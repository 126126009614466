import axios, { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useAlerts } from '../../app/alerts';
import { useSettings } from '../../app/settings/useSettings';
import { BackendError } from '../../api/contract/backendError';
import { ReportDownloadLink } from '../../api/contract/reportDownloadLink';

export const useReportsActions = () => {
	const { addInfo, addError, clearInfoAlerts } = useAlerts();
	const settings = useSettings();
	const maxReportDownloadCount = settings?.maxReportDownloadCount ?? 0;

	const validateReportsCount = useCallback(
		(count: number) => {
			const isLimitExceeded = count > maxReportDownloadCount;
			if (isLimitExceeded) addError(`Please select no more than ${maxReportDownloadCount} reports for downloading or sharing.`);

			return !isLimitExceeded;
		},
		[addError, maxReportDownloadCount],
	);

	const generateDownloadLink = useCallback(
		async (reportUids: string[]) => {
			try {
				const result = await axios.post<ReportDownloadLink>('/api/Reports/GenerateDownloadLink', reportUids);
				return result.data.uid;
			} catch (error) {
				const errorText = (error as AxiosError<BackendError>).response?.data?.reportsSize
					? 'Please select files with a total size of no more than 10 GB for downloading or sharing.'
					: `The specified ${reportUids.length > 1 ? 'reports are not' : 'report is not'} available.`;

				addError(errorText);

				return null;
			}
		},
		[addError],
	);

	const download = useCallback(
		async (reportUids: string[]) => {
			if (!validateReportsCount(reportUids.length)) return;

			const linkUid = await generateDownloadLink(reportUids);

			if (linkUid) {
				const url = new URL('/api/Reports/Download', window.location.origin);
				url.searchParams.append('downloadLinkUid', linkUid);
				window.location.assign(url);
			}
		},
		[generateDownloadLink, validateReportsCount],
	);

	const share = useCallback(
		async (reportUids: string[]) => {
			if (!validateReportsCount(reportUids.length)) return;

			const linkUid = await generateDownloadLink(reportUids);

			if (linkUid) {
				navigator.clipboard.writeText(new URL(`Reports/Download/${linkUid}`, window.location.href).toString());
				clearInfoAlerts();
				addInfo('Link for downloading copied to clipboard');
			}
		},
		[addInfo, clearInfoAlerts, generateDownloadLink, validateReportsCount],
	);

	const [isMultipleReportsDownloading, setMultipleReportsDownloading] = useState(false);
	const [isMultipleReportsSharing, setMultipleReportsSharing] = useState(false);

	const onReportDownload = useCallback((reportUid: string) => download([reportUid]), [download]);
	const onReportsDownload = useCallback(
		(reportUids: string[]) => {
			setMultipleReportsDownloading(true);
			download(reportUids).finally(() => setMultipleReportsDownloading(false));
		},
		[download],
	);

	const onReportShare = useCallback((reportUid: string) => share([reportUid]), [share]);
	const onReportsShare = useCallback(
		(reportUids: string[]) => {
			setMultipleReportsSharing(true);
			share(reportUids).finally(() => setMultipleReportsSharing(false));
		},
		[share],
	);

	return {
		isMultipleReportsDownloading,
		isMultipleReportsSharing,
		onReportDownload,
		onReportsDownload,
		onReportShare,
		onReportsShare,
	};
};

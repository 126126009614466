import { Routes, Route } from 'react-router-dom';
import { AuthorizedUsersBoundary } from '../../app/security/AuthorizedUsersBoundary';
import { UnauthorizedUsersBoundary } from '../../app/security/UnauthorizedUsersBoundary';
import { Test } from '../01Test/Test';
import { TestForm } from '../01Test/TestForm';
import { NotFound } from '../Errors/NotFound';
import { Home } from '../Home/Home';
import { Reports } from '../Reports/Reports';
import { ReportsDownload } from '../Reports/ReportsDownload';
import { SecurityRoles } from '../../api/types/SecurityRoles';

export function Routing(): JSX.Element {
	return (
		<Routes>
			<Route element={<UnauthorizedUsersBoundary />}>
				<Route path="/" element={<Home />} />
				<Route element={<AuthorizedUsersBoundary allowedFor={[SecurityRoles.ReportViewer]} />}>
					<Route path="/reports" element={<Reports />} />
					<Route path="/reports/download/:downloadLinkUid" element={<ReportsDownload />} />
				</Route>
			</Route>
			<Route path="/test" element={<Test />} />
			<Route path="/test/form" element={<TestForm />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, AlertContextProps, AlertParameters, AlertType } from './AlertProvider.types';

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider = ({ children }: PropsWithChildren<{}>) => {
	const location = useLocation();

	const [alerts, setAlerts] = useState<Alert[]>([]);

	useEffect(() => setAlerts([]), [location]);

	const alertsContextValue = useMemo(() => ({ alerts, setAlerts }), [alerts, setAlerts]);

	return <AlertContext.Provider value={alertsContextValue}>{children}</AlertContext.Provider>;
};

export const useAlerts = (): AlertParameters => {
	const contextData = useContext(AlertContext);

	if (!contextData) {
		throw new Error('useAlerts must be used only inside AlertBoundary.');
	}

	const addAlert = useCallback(
		({ type, message }: Alert) =>
			contextData.setAlerts((a) =>
				a.find((x) => x.message === message && x.type === type)
					? a.map((x) => (x.message === message && x.type === type ? { ...x } : x))
					: [...a, { message, type }],
			),
		[contextData],
	);

	const addError = useCallback((message: string) => addAlert({ message, type: 'error' }), [addAlert]);

	const addInfo = useCallback((message: string) => addAlert({ message, type: 'info' }), [addAlert]);

	const removeAlert = useCallback(
		(title: string, type: AlertType) => contextData.setAlerts((a) => a.filter((f) => !(f.message === title && f.type === type))),
		[contextData],
	);

	const clearAlerts = useCallback(() => contextData.setAlerts([]), [contextData]);

	const clearErrorAlerts = useCallback(() => contextData.setAlerts((a) => a.filter((x) => x.type !== 'error')), [contextData]);

	const clearInfoAlerts = useCallback(() => contextData.setAlerts((a) => a.filter((x) => x.type !== 'info')), [contextData]);

	return {
		alerts: contextData.alerts,
		addAlert,
		addError,
		addInfo,
		removeAlert,
		clearAlerts,
		clearErrorAlerts,
		clearInfoAlerts,
	};
};

import { useCallback, useRef, useState } from 'react';
import { Option } from '../../dropdown/Option';
import { IconChevron, XIcon, IconMultiplePages, IconOnePage, IconOnePageX } from '../../icons';
import { OptionMenuContainer } from '../../dropdown/OptionMenuContainer';
import { TableHeaderCheckBoxProps } from './TableHeaderCheckBox.types';
import { CheckBox } from '../../checkbox/CheckBox';

export function TableHeaderCheckBox(props: TableHeaderCheckBoxProps): JSX.Element {
	const { testid, disabled, selectAllDisabled, allRowsSelected, anyRowSelected, anyRowOnPageSelected, allRowsOnPageSelected } = props; // prettier-ignore
	const { onAllRowsSelect, onCurrentPageRowsSelect, onCurrentPageRowsClear, onSelectionClear } = props;

	const checked = allRowsSelected || (anyRowSelected ? 'indeterminate' : false);

	const [expanded, setExpanded] = useState<boolean>(false);
	const optionMenuAnchor = useRef<HTMLDivElement>(null);

	const handleAllRowsSelected = useCallback(() => { onAllRowsSelect(); setExpanded(false); }, [onAllRowsSelect]); // prettier-ignore
	const handleCurrentPageRowsSelected = useCallback(() => { onCurrentPageRowsSelect(); setExpanded(false); }, [onCurrentPageRowsSelect]); // prettier-ignore
	const handleCurrentPageRowsClear = useCallback(() => { onCurrentPageRowsClear(); setExpanded(false); }, [onCurrentPageRowsClear]); // prettier-ignore
	const handleSelectionClear = useCallback(() => { onSelectionClear(); setExpanded(false); }, [onSelectionClear]); // prettier-ignore

	return (
		<th className="h-0 w-10">
			<div
				ref={optionMenuAnchor}
				data-testid={`${testid}-table-checkbox-header-inner`}
				className="group relative flex h-full w-full items-center pl-2"
			>
				<CheckBox
					testid={`${testid}-table-checkbox-header-all`}
					checked={disabled ? false : checked}
					disabled={disabled}
					onChange={() => setExpanded((s) => !s)}
				/>

				{!disabled && (
					<button
						data-testid={`${testid}-table-checkbox-header-button`}
						className="invisible flex h-full items-center pl-1.5 group-hover:visible"
						type="button"
						onClick={() => setExpanded((s) => !s)}
					>
						<span className="w-5 -rotate-90">
							<IconChevron />
						</span>
					</button>
				)}
			</div>

			<OptionMenuContainer
				testid={`${testid}-table-checkbox-header-container`}
				anchor={optionMenuAnchor}
				width="large"
				placement="bottom-start"
				expanded={expanded}
				onCollapse={() => setExpanded(false)}
			>
				<Option
					testid={`${testid}-table-checkbox-header-select-all`}
					text="Select on all pages"
					disabled={selectAllDisabled || allRowsSelected}
					icon={<IconMultiplePages />}
					onClick={handleAllRowsSelected}
				/>
				<Option
					testid={`${testid}-table-checkbox-header-select-current`}
					text="Select on current page"
					disabled={allRowsOnPageSelected || allRowsSelected}
					icon={<IconOnePage />}
					onClick={handleCurrentPageRowsSelected}
				/>
				<Option
					testid={`${testid}-table-checkbox-header-deselect-current`}
					text="Deselect on current page"
					disabled={!anyRowOnPageSelected}
					icon={<IconOnePageX />}
					onClick={handleCurrentPageRowsClear}
				/>
				<Option
					testid={`${testid}-table-checkbox-header-deselect-all`}
					text="Deselect on all pages"
					disabled={!anyRowSelected}
					icon={<XIcon />}
					onClick={handleSelectionClear}
				/>
			</OptionMenuContainer>
		</th>
	);
}

import { Navigate } from 'react-router-dom';
import { useTitle } from '../../hooks/useTitle';
import { useUserIdentity } from '../../app/security/UserIdentityProvider';
import { LoadingContainer } from '../../components';

export function Home(): JSX.Element {
	useTitle('CBA Partner Portal');

	const { isAuthenticated } = useUserIdentity();

	if (!isAuthenticated) window.location.replace('/account/signin');

	return (
		<LoadingContainer testid="home" isLoading={!isAuthenticated} noContentWhileLoading noLoaderOverlay>
			<Navigate to="/reports" />
		</LoadingContainer>
	);
}

import { usePickerNavigation } from '@ipis-ui-kit/react-headless';
import { MainPickerPanelFooterProps } from './MainPickerPanelFooter.types';

export function MainPickerPanelFooter({ testid }: MainPickerPanelFooterProps): JSX.Element {
	const { setCurrentDateTime } = usePickerNavigation();

	return (
		<div
			className="flex w-full items-center justify-between border-t border-t-grayscale-200 p-1"
			data-testid={`${testid}-picker-header`}
		>
			<button
				type="button"
				onClick={setCurrentDateTime}
				data-testid={testid}
				className="w-full select-none rounded p-2 text-xs text-primary focus:outline-1 focus:outline"
			>
				Today
			</button>
		</div>
	);
}

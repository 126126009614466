import { Error } from '../components/error/Error';
import { IconErrorStop } from '../../components/icons/IconErrorStop';

export function Forbidden(): JSX.Element {
	return (
		<Error
			title="Forbidden"
			description="We are sorry, you do not have sufficient rights to view this page. Please contact your account manager."
			icon={<IconErrorStop />}
			testid="forbidden-error"
		/>
	);
}

import { ErrorProps } from './Error.types';

export function Error({ testid, title, description, icon }: ErrorProps): JSX.Element {
	return (
		<div data-testid={testid} className="flex h-full flex-grow flex-col items-center justify-center">
			{icon && <div className="w-52">{icon}</div>}

			<div className="text-xl font-medium">{title}</div>
			{description && <div>{description}</div>}
		</div>
	);
}

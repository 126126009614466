import { IconClose } from '../icons';
import { AlertToastProps, AlertType } from './AlertToast.types';

const alertTypeMap: { [key in AlertType]: { background: string; text: string } } = {
	error: {
		background: 'bg-validation',
		text: 'text-validation',
	},
	info: {
		background: 'bg-information',
		text: 'text-information',
	},
};

export function AlertToast({ testid, type, message, onClose }: AlertToastProps): JSX.Element {
	return (
		<div
			data-testid={`${testid}-toast`}
			className={`flex w-full flex-row items-center justify-between py-4 ${alertTypeMap[type].background}`}
		>
			<span className={`pl-8 font-light ${alertTypeMap[type].text}`}>{message}</span>

			<button
				data-testid={`${testid}-toast-close`}
				type="button"
				className={`mr-8 w-6 ${alertTypeMap[type].text}`}
				onClick={onClose}
			>
				<IconClose />
			</button>
		</div>
	);
}

import { useModal } from './useModal';
import { ModalHeaderProps } from './ModalHeader.types';

import { IconClose } from '../icons';

export function ModalHeader({ children }: ModalHeaderProps): JSX.Element {
	const { onClose, onMouseDown } = useModal();

	return (
		<div
			role="presentation"
			onMouseDown={onMouseDown}
			className="flex w-full cursor-move flex-col justify-around rounded-t-2xl p-6"
		>
			<button
				type="button"
				onClick={onClose}
				className="absolute right-0 top-0 mr-3 mt-3 h-6 w-6 rounded-lg text-grayscale-400 hover:shadow-gray-100"
			>
				<IconClose />
			</button>

			<div className="pointer-events-none text-2xl text">{children}</div>
		</div>
	);
}

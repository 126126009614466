import { useEffect, useRef } from 'react';
import { TextFieldProps } from './TextField.types';

const underlineError = 'border-b-2 border-danger group-hover:border-danger-highlight';
const underline = 'border-b-2 border-grayscale-300 group-hover:border-highlight peer-focus:border-b-primary-highlight';
const wrapperStandard = 'border-grayscale-300 hover:border-highlight';
const wrapperStandardError = 'border-danger hover:border-danger-highlight';

export function TextField(props: TextFieldProps): JSX.Element {
	const { testid, value, type, placeholder, name, variant, disabled, multiline, error, helperText, step } = props;
	const { iconLeft, iconRight, iconRightSizing } = props;
	const { onClick, onChange, onBlur } = props;

	const definedVariant = variant ?? 'standard';

	const textarea = useRef<HTMLTextAreaElement>(null);
	useEffect(() => {
		if (!textarea.current) return;
		textarea.current.style.height = 'auto';
		textarea.current.style.height = `${textarea.current.scrollHeight}px`;
	}, [value]);

	if (multiline && type && type !== 'text') {
		throw Error('Multiline can only be text');
	}

	return (
		<div>
			<div
				data-testid={`${testid}-text-field-wrapper`}
				className={`group relative flex items-center gap-2 border-2 border-b-0 bg px-3 py-2 transition-all
					${definedVariant === 'standard' && (error ? wrapperStandardError : wrapperStandard)}
					${definedVariant === 'underlined' && 'border-opposite'}
					${disabled ? 'pointer-events-none bg-grayscale-100 text-grayscale-300 shadow-none' : 'text'}`}
			>
				{multiline ? (
					<textarea
						data-testid={`${testid}-text-field-textarea`}
						className="peer order-2 min-w-0 flex-grow resize-y overflow-hidden font-light placeholder outline-none transition-colors placeholder:font-light disabled:bg-grayscale-100"
						name={name}
						value={value}
						ref={textarea}
						placeholder={placeholder}
						disabled={disabled}
						readOnly={!onChange}
						onClick={onClick}
						onChange={onChange}
						onBlur={onBlur}
					/>
				) : (
					<input
						data-testid={`${testid}-text-field-input`}
						className="peer order-2 h-6 min-w-0 flex-grow font-light placeholder outline-none transition-colors placeholder:font-light disabled:bg-grayscale-100"
						value={value}
						placeholder={placeholder}
						disabled={disabled}
						step={step}
						type={type}
						readOnly={!onChange}
						onClick={onClick}
						onChange={onChange}
						name={name}
						onBlur={onBlur}
					/>
				)}

				{iconLeft && (
					<div
						data-testid={`${testid}-text-field-icon-left`}
						className="order-1 w-6 flex-shrink-0 flex-grow-0 self-start text-grayscale-400 peer-focus:text-primary"
					>
						{iconLeft}
					</div>
				)}

				{iconRight && (
					<div
						data-testid={`${testid}-text-field-icon-right`}
						className={`order-3 flex-shrink-0 flex-grow-0 self-start text-grayscale-400 
							${iconRightSizing === 'unrestricted' ? '' : 'w-6'}`}
					>
						{iconRight}
					</div>
				)}

				<div
					data-testid={`${testid}-text-field-underline`}
					className={`absolute -left-0.5 -right-0.5 bottom-0 transition-all ${error ? underlineError : underline}`}
				/>
			</div>
			{error && helperText && <div className="text-xs font-light text-danger">{helperText}</div>}
		</div>
	);
}

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ErrorProvider } from './app/errors/ErrorProvider';
import { App } from './app/initialization/App';
import { AlertProvider } from './app/alerts';
import { UserIdentityProvider } from './app/security/UserIdentityProvider';
import { SettingsProvider } from './app/settings/useSettings';

import './index.css';
import { configureAxiosMiddlewares } from './lib/global/axiosMiddlewares';

configureAxiosMiddlewares();

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: false,
		},
	},
});

createRoot(document.getElementById('root') as Element).render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<ErrorProvider>
					<AlertProvider>
						<UserIdentityProvider>
							<SettingsProvider>
								<App />
							</SettingsProvider>
						</UserIdentityProvider>
					</AlertProvider>
				</ErrorProvider>
			</BrowserRouter>
		</QueryClientProvider>
	</StrictMode>,
);

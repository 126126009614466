import { RefObject, useCallback } from 'react';

const minWidthPx = 50;

export const useColumnResize = (column: RefObject<HTMLDivElement>, onChange?: (newSize: number) => void) => {
	const onResize = useCallback(
		(e: MouseEvent, resizeAreaOffsetPx: number) => {
			e.preventDefault();
			const { x } = column.current!.getBoundingClientRect();
			onChange?.(Math.max(minWidthPx, resizeAreaOffsetPx + e.clientX - x));
		},
		[column, onChange],
	);

	const onResizeStart = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const resizeAreaOffsetPx = event.currentTarget.offsetWidth / 2;
			const onResizeEventHandler = (e: MouseEvent) => onResize(e, resizeAreaOffsetPx);

			const onResizeStop = () => {
				window.removeEventListener('mousemove', onResizeEventHandler);
				window.removeEventListener('mouseup', onResizeStop);
			};

			window.addEventListener('mousemove', onResizeEventHandler);
			window.addEventListener('mouseup', onResizeStop);
		},
		[onResize],
	);

	return { onResizeStart };
};

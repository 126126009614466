import { memo } from 'react';
import { useDay } from '@ipis-ui-kit/react-headless';

import { DayProps } from './Day.types';

export const Day = memo((props: DayProps) => {
	const { testid, date, dayLabel } = props;

	const { onSelect, tabIndex, isSelected, isToday } = useDay({ dayDateTime: date });

	return dayLabel ? (
		<button
			type="button"
			onClick={onSelect}
			tabIndex={tabIndex}
			data-testid={testid}
			className={`select-none rounded p-2 text-xs focus:outline-1 focus:outline
				${isSelected ? 'bg-primary text-opposite' : 'bg text'}
				${isToday && !isSelected ? 'bg-grayscale-200 font-bold' : ''}`}
		>
			{dayLabel}
		</button>
	) : (
		<div />
	);
});

import { FromProps } from './Form.types';

export function Form(props: FromProps): JSX.Element {
	const { testid, children } = props;

	return (
		<div data-testid={`${testid}-form`} className="flex flex-col gap-3 overflow-hidden font-light sm:w-full md:w-6/12 xl:w-5/12">
			{children}
		</div>
	);
}

import { useMemo } from 'react';

export const useReportsSelectors = (
	partners: { partnerCode: string }[] | undefined,
	resellers: { resellerCode: string }[] | undefined,
	reportTypes: { reportTypeName: string }[] | undefined,
) => {
	const partnerRecords = useMemo(
		() =>
			partners ? Object.fromEntries(partners.filter((x) => !!x.partnerCode).map((x) => [x.partnerCode, x.partnerCode])) : {},
		[partners],
	);

	const resellerRecords = useMemo(
		() =>
			resellers ? Object.fromEntries(resellers.filter((x) => !!x.resellerCode).map((x) => [x.resellerCode, x.resellerCode])) : {},
		[resellers],
	);

	const reportTypeRecords = useMemo(
		() => (reportTypes ? Object.fromEntries(reportTypes.map((x) => [x.reportTypeName, x.reportTypeName])) : {}),
		[reportTypes],
	);

	return { partnerRecords, resellerRecords, reportTypeRecords };
};

import axios, { AxiosError } from 'axios';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from 'react-query';
import { Settings } from '../../api/contract';
import { Forbidden } from '../../pages/Errors/Forbidden';
import { Unknown } from '../../pages/Errors/Unknown';
import { useError } from '../errors/ErrorProvider';

const SettingsContext = createContext<Settings | null>(undefined!);

const handleRequestErrors = (error: AxiosError): JSX.Element => (error?.response?.status === 403 ? <Forbidden /> : <Unknown />);

export function SettingsProvider({ children }: PropsWithChildren<{}>) {
	const { setOverlappingError } = useError();

	const onError = (error: AxiosError) => setOverlappingError('article', { fallbackComponent: () => handleRequestErrors(error) });
	const { data } = useQuery('settings', () => axios.get<Settings>('/api/settings').then((x) => x.data), { onError });

	return <SettingsContext.Provider value={data ?? null}>{children}</SettingsContext.Provider>;
}

export const useSettings = () => {
	const contextData = useContext(SettingsContext);
	if (contextData === undefined) {
		throw new Error(`${useSettings.name} should be only inside ${SettingsProvider.name}`);
	}

	return contextData;
};

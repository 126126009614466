import { Fragment } from 'react';
import { FocusTrap } from '@headlessui/react';
import { DateTimePickerContextProvider } from '@ipis-ui-kit/react-headless';

import { MainPickerPanelProps } from './MainPickerPanel.types';
import { MainPickerPanelHeader } from './MainPickerPanelHeader';
import { MainPickerPanelBody } from './MainPickerPanelBody';
import { MainPickerPanelFooter } from './MainPickerPanelFooter';

export function MainPickerPanel(props: MainPickerPanelProps): JSX.Element {
	const { testid, timeZone, mode, date, monthFormat, timeIntervalStep, timeFormat, onChange, onClose } = props;
	const options = { timeZone, mode, monthFormat, timeFormat, timeIntervalStep };

	return (
		<DateTimePickerContextProvider dateTime={date!} options={options} onChange={onChange} onClose={onClose}>
			<FocusTrap as={Fragment}>
				<div data-testid={`${testid}-main`} className="rounded bg px-2 pt-2 shadow-floating">
					<MainPickerPanelHeader testid={testid} />

					<MainPickerPanelBody testid={testid} />

					<MainPickerPanelFooter testid={testid} />
				</div>
			</FocusTrap>
		</DateTimePickerContextProvider>
	);
}

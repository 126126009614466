export function IconMultiplePages() {
	return (
		<svg viewBox="0 0 20 20" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5 3.5H4.5V12.5H7V7.5V6H8.5H11.5V3.5ZM13 6H15.5H17V7.5V16.5V18H15.5H8.5H7V16.5V14H4.5H3V12.5V3.5V2H4.5H11.5H13V3.5V6ZM8.5 7.5H15.5V16.5H8.5V7.5Z"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.5 3.5V6H7V12.5H4.5V3.5H11.5ZM13 6V2H3V14H7V18H17V6H13ZM8.5 7.5V16.5H15.5V7.5H8.5Z"
			/>
		</svg>
	);
}

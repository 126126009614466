import { useFloating } from '@floating-ui/react-dom';
import { useEffect } from 'react';
import { OptionMenu } from './OptionMenu';
import { OptionMenuContainerProps } from './OptionMenuContainer.types';
import { getAnimation, getFloatingParams, getFloatingReference } from './OptionMenuContainer.logic';
import { Portal } from '../portal/Portal';

function OptionMenuContainer(props: OptionMenuContainerProps): JSX.Element {
	const { children, expanded, width, anchor, testid, onCollapse } = props;

	const { x, y, refs, strategy, update } = useFloating(getFloatingParams(props));

	useEffect(() => {
		if (anchor) refs.setReference(getFloatingReference(anchor));

		window.addEventListener('resize', update);
		return () => window.removeEventListener('resize', update);
	}, [update, anchor, refs]);

	useEffect(() => {
		update();
	}, [update, expanded]);

	return (
		<Portal>
			<div
				role="toolbar"
				data-testid={`${testid}-option-menu-container`}
				ref={(e) => expanded && e?.focus({ preventScroll: true })}
				onBlur={() => onCollapse && expanded && onCollapse()}
				tabIndex={0}
			>
				<OptionMenu
					testid={testid}
					ref={refs.setFloating}
					style={{ top: y || '', left: x || '', position: strategy }}
					width={width}
					expanded={expanded}
					animationDirection={getAnimation(anchor, y)}
				>
					{children}
				</OptionMenu>
			</div>
		</Portal>
	);
}

export { OptionMenuContainer };

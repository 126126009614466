import { IconChevronLeft, IconChevronRight, IconFirstPage, IconLastPage } from '../../../icons';
import { getPageNumbers } from './pagesComputations';
import { PaginationProps } from './Pagination.types';
import { PaginationItem } from './PaginationItem';

export function Pagination(props: PaginationProps): JSX.Element {
	const { totalRecords, pageLimit, currentPage, testid } = props;
	const { onPageChanged } = props;

	const firstPage = 1;
	const totalPages = Math.ceil(totalRecords / pageLimit);
	const pages = getPageNumbers(currentPage, totalPages);

	return (
		<>
			<div>
				<p data-testid={`${testid}-pagination-summary`} className="pb-2 pt-3 text-sm text">
					Showing <span className="font-medium">{Math.min(totalRecords, (currentPage - 1) * pageLimit + 1)}</span> to{' '}
					<span className="font-medium">{Math.min(currentPage * pageLimit, totalRecords)}</span> of{' '}
					<span className="font-medium">{totalRecords}</span> results
				</p>
			</div>
			<div>
				<nav aria-label="Pagination" className="relative inline-flex gap-1 rounded-md">
					<PaginationItem
						isIcon
						testid={`${testid}-first`}
						disabled={!totalPages || currentPage === firstPage}
						onClick={() => onPageChanged(1)}
					>
						<IconFirstPage />
					</PaginationItem>
					<PaginationItem
						isIcon
						testid={`${testid}-previous`}
						disabled={!totalPages || currentPage === firstPage}
						onClick={() => onPageChanged(currentPage - 1)}
					>
						<IconChevronLeft />
					</PaginationItem>
					{pages.map((page: number) => (
						<PaginationItem
							key={page}
							highlighted={page === currentPage}
							testid={`${testid}-${page.toString()}`}
							onClick={() => onPageChanged(page)}
						>
							{page}
						</PaginationItem>
					))}
					<PaginationItem
						isIcon
						testid={`${testid}-next`}
						disabled={!totalPages || currentPage === totalPages}
						onClick={() => onPageChanged(currentPage + 1)}
					>
						<IconChevronRight />
					</PaginationItem>
					<PaginationItem
						isIcon
						testid={`${testid}-last`}
						disabled={!totalPages || currentPage === totalPages}
						onClick={() => onPageChanged(totalPages)}
					>
						<IconLastPage />
					</PaginationItem>
				</nav>
			</div>
		</>
	);
}

import { FormElementProps } from './FormElement.types';

export function FormElement(props: FormElementProps): JSX.Element {
	const { testid, label, children } = props;

	return (
		<div>
			{label && (
				<div data-testid={`${testid}-form-element-label`} className="mb-0.5 text-sm text-grayscale-600">
					{label}
				</div>
			)}
			<div data-testid={`${testid}-form-element`}>{children}</div>
		</div>
	);
}

import { ODataTableFilter, numberExpressions } from '@ipis-ui-kit/odata-table';
import { TableNumberFilterOptions } from '../../ui/filtration/TableFilter.types';
import { numberInput } from '../../ui/filtration/TableFilterModalInputs';

export const eq = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'equals',
	apply: numberExpressions.equals,
	input: numberInput(options),
});

export const ne = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'does not equal',
	apply: numberExpressions.notEquals,
	input: numberInput(options),
});

export const gt = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'greater than',
	apply: numberExpressions.greaterThan,
	input: numberInput(options),
});

export const lt = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'less than',
	apply: numberExpressions.lessThan,
	input: numberInput(options),
});

export const ge = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'greater or equal to',
	apply: numberExpressions.greaterOrEqualTo,
	input: numberInput(options),
});

export const le = (options?: TableNumberFilterOptions): ODataTableFilter<number> => ({
	title: 'less or equal to',
	apply: numberExpressions.lessOrEqualTo,
	input: numberInput(options),
});

const filters = [eq, ne, gt, ge, lt, le];

export const all = (options?: TableNumberFilterOptions) => filters.map((filter) => filter(options));

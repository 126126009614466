export function IconCalendar() {
	return (
		<svg width="100%" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.5 11C4.22386 11 4 11.2239 4 11.5V12.5C4 12.7761 4.22386 13 4.5 13H5.5C5.77614 13 6 12.7761 6 12.5V11.5C6 11.2239 5.77614 11 5.5 11H4.5Z"
				fill="currentColor"
			/>
			<path
				d="M4 15.5C4 15.2239 4.22386 15 4.5 15H5.5C5.77614 15 6 15.2239 6 15.5V16.5C6 16.7761 5.77614 17 5.5 17H4.5C4.22386 17 4 16.7761 4 16.5V15.5Z"
				fill="currentColor"
			/>
			<path
				d="M9.5 11C9.22386 11 9 11.2239 9 11.5V12.5C9 12.7761 9.22386 13 9.5 13H10.5C10.7761 13 11 12.7761 11 12.5V11.5C11 11.2239 10.7761 11 10.5 11H9.5Z"
				fill="currentColor"
			/>
			<path
				d="M9 15.5C9 15.2239 9.22386 15 9.5 15H10.5C10.7761 15 11 15.2239 11 15.5V16.5C11 16.7761 10.7761 17 10.5 17H9.5C9.22386 17 9 16.7761 9 16.5V15.5Z"
				fill="currentColor"
			/>
			<path
				d="M14.5 11C14.2239 11 14 11.2239 14 11.5V12.5C14 12.7761 14.2239 13 14.5 13H15.5C15.7761 13 16 12.7761 16 12.5V11.5C16 11.2239 15.7761 11 15.5 11H14.5Z"
				fill="currentColor"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V2C1.79086 2 0 3.79086 0 6V17C0 19.2091 1.79086 21 4 21H16C18.2091 21 20 19.2091 20 17V6C20 3.79086 18.2091 2 16 2V1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1V2H6V1ZM16 4C17.1046 4 18 4.89543 18 6V7H2V6C2 4.89543 2.89543 4 4 4H16ZM2 17V9H18V17C18 18.1046 17.1046 19 16 19H4C2.89543 19 2 18.1046 2 17Z"
				fill="currentColor"
			/>
		</svg>
	);
}

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUserIdentity } from '../../app/security/UserIdentityProvider';
import { Button } from '../../components/button/Button';
import { CheckBox } from '../../components/checkbox/CheckBox';
import { Form } from '../../components/form/Form';
import { FormElement } from '../../components/form/FormElement';
import { FormControls } from '../../components/form/FormControls';
import { FormHelperText } from '../../components/form/FormHelperText';
import { IconBriefCase } from '../../components/icons/IconBriefCase';
import { IconComment } from '../../components/icons/IconComment';
import { IconGlobe } from '../../components/icons/IconGlobe';
import { Select } from '../../components/select/Select';
import { TextField } from '../../components/text-field/TextField';
import { TestFormFields } from './TestForm.types';

export function TestForm(): JSX.Element {
	const { identity } = useUserIdentity();
	const formik = useFormik<TestFormFields>({
		initialValues: {
			country: '',
			company: '',
			comment: '',
			privacy: false,
		},
		validationSchema: Yup.object({
			country: Yup.string().required(),
			company: Yup.string().max(20).required(),
			comment: Yup.string().max(204),
			privacy: Yup.bool().isTrue(),
		}),
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	return (
		<Form testid="form">
			<FormHelperText testid="helper">
				You logged in to CBA Partner Portal for the first time. Bla bla bla lorem ipsum dolor sit amet consectetur adipisicing
				elit. Temporibus deserunt a accusamus exercitationem repellendus nemo, magnam illum ratione aspernatur asperiores
				molestiae neque eos aperiam sequi? Illum commodi facere assumenda aliquam!
			</FormHelperText>

			<FormElement testid="email" label="Email">
				{identity?.email}
			</FormElement>

			<FormElement testid="geo" label="Country">
				<Select
					testid="geo"
					placeholder="Country"
					icon={<IconGlobe />}
					selectedId={formik.values.country}
					items={[
						{ id: 'ru', value: 'Русь' },
						{ id: 'not ru', value: ' Не Русь' },
					]}
					error={formik.touched.country && !!formik.errors.country}
					helperText={formik.errors.country}
					onChange={(c) => formik.setFieldValue('country', c.id)}
				/>
			</FormElement>

			<FormElement testid="company" label="Company">
				<TextField
					testid="comp"
					iconLeft={<IconBriefCase />}
					placeholder="Company"
					value={formik.values.company}
					error={formik.touched.company && !!formik.errors.company}
					helperText={formik.errors.company}
					onChange={(c) => formik.setFieldValue('company', c.target.value)}
				/>
			</FormElement>

			<FormElement testid="comment" label="Comment">
				<TextField
					testid="comm"
					placeholder="Comment"
					iconLeft={<IconComment />}
					multiline
					value={formik.values.comment}
					error={formik.touched.comment && !!formik.errors.comment}
					helperText={formik.errors.comment}
					onChange={(c) => formik.setFieldValue('comment', c.target.value)}
				/>
			</FormElement>

			<FormElement testid="privacy">
				<CheckBox
					testid="privacy"
					checked={formik.values.privacy}
					error={formik.touched.privacy && !!formik.errors.privacy}
					helperText={formik.errors.privacy}
					onChange={(c) => formik.setFieldValue('privacy', c.target.checked)}
				>
					I agree that i am cool. And Bla bla bla lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus deserunt a
					accusamus exercitationem repellendus nemo, magnam illum ratione aspernatur asperiores
				</CheckBox>
			</FormElement>

			<FormControls testid="controls">
				<Button testid="create" onClick={formik.submitForm}>
					Create
				</Button>
				<Button testid="delete" variant="dangerous">
					Delete
				</Button>
				<Button testid="doit" variant="outlined">
					Do nothing
				</Button>
			</FormControls>
		</Form>
	);
}

import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useArticleError } from '../../app/errors/ErrorBoundary';
import { Title } from '../../components';
import { IconErrorNotFound, IconErrorStop } from '../../components/icons';
import { useTitle } from '../../hooks/useTitle';
import { Error } from '../components/error/Error';
import { Unknown } from '../Errors/Unknown';
import { RouteParams } from './ReportsDownload.types';

const statusCodeErrors: { [key: number]: JSX.Element } = {
	404: (
		<Error
			title={`The reports you are looking for don't exist`}
			description="You may have entered an incorrect address or the reports were deleted."
			icon={<IconErrorNotFound />}
			testid="not-found-error"
		/>
	),
	403: (
		<Error
			title="Forbidden"
			description="We are sorry, you do not have sufficient rights to view these reports. Please contact your account manager."
			icon={<IconErrorStop />}
			testid="forbidden-error"
		/>
	),
};

export function ReportsDownload(): JSX.Element {
	const { setError } = useArticleError();
	useTitle('Download Reports');

	const { downloadLinkUid } = useParams<RouteParams>();

	const getErrorFallback = (error: AxiosError<any, any>) => {
		if (error.response?.status && error.response?.status in statusCodeErrors) return statusCodeErrors[error.response.status];
		return <Unknown />;
	};

	const downloadReport = () => {
		const url = new URL('/api/Reports/Download', window.location.origin);
		url.searchParams.append('downloadLinkUid', downloadLinkUid!);
		window.location.assign(url);
	};

	const { isLoading } = useQuery(
		[downloadLinkUid],
		() => axios.get('/api/Reports/CheckDownloadLink', { params: { downloadLinkUid } }),
		{
			onError: (error: AxiosError<any, any>) => setError({ fallbackComponent: () => getErrorFallback(error) }),
			onSuccess: downloadReport,
			retry: false,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			refetchOnMount: false,
		},
	);

	return (
		<>
			<Title testid="reports-download" level="h1">
				Download Reports
			</Title>

			{isLoading ? <p>Preparing your download...</p> : <p>Your download will begin shortly in several seconds...</p>}
		</>
	);
}

import { useFloating } from '@floating-ui/react-dom';
import { Menu, Transition } from '@headlessui/react';
import { CSSProperties, Fragment } from 'react';
import { whileElementsMounted } from '../../../lib/floating';
import { IconChevron } from '../../icons/IconChevron';
import { Portal } from '../../portal/Portal';
import { HeaderMenuProps } from './HeaderMenu.types';

export function HeaderMenu(props: HeaderMenuProps): JSX.Element {
	const { testid, text, icon, filled, placement, children } = props;

	const { x, y, reference, floating, strategy, refs } = useFloating({
		placement: placement ?? 'bottom-end',
		whileElementsMounted,
	});

	const position: CSSProperties = {
		top: y ?? 0,
		left: x ?? 0,
		position: strategy,
		minWidth: refs.reference.current?.getBoundingClientRect().width,
	};

	return (
		<Menu as={Fragment}>
			{({ open }) => (
				<>
					<Menu.Button className="outline-none">
						<div
							data-testid={`${testid}-header-menu`}
							className={`group relative flex h-16 cursor-pointer items-center overflow-hidden px-5 outline-none 
								${filled ? 'bg-primary' : 'bg'}`}
							ref={reference}
						>
							<div
								data-testid={`${testid}-header-menu-inner`}
								className={`flex items-center gap-2 ${filled ? 'text-opposite' : 'text'}`}
							>
								{icon && <div className="block w-4 flex-shrink-0">{icon}</div>}

								{text && <div className="text-sm">{text}</div>}

								<div className={`block w-4 flex-shrink-0 transition-transform ${open ? 'rotate-90' : '-rotate-90'} `}>
									<IconChevron />
								</div>
							</div>
							<div
								data-testid={`${testid}-header-menu-indicator`}
								className={`absolute bottom-0 left-0 right-0 h-1 transition-opacity group-hover:opacity-100 
									${filled ? 'bg-primary-highlight' : 'bg-primary-highlight-opposite'}
									${open ? 'opacity-100' : 'opacity-0'}`}
							/>
						</div>
					</Menu.Button>

					<Portal>
						<div style={position} ref={floating} data-testid={`${testid}-header-menu-options`}>
							<Transition
								as={Fragment}
								enter="transition-opacity ease-out duration-100"
								enterFrom="transform opacity-0"
								enterTo="transform opacity-100"
								leave="transition-opacity ease-in duration-75"
								leaveFrom="transform opacity-100"
								leaveTo="transform opacity-0"
							>
								<Menu.Items as={Fragment}>
									<div className="bg shadow-floating outline-none">{children}</div>
								</Menu.Items>
							</Transition>
						</div>
					</Portal>
				</>
			)}
		</Menu>
	);
}

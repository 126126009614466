/* eslint-disable react/jsx-props-no-spreading */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { TableRow } from '@ipis-ui-kit/table-types';
import { XIcon } from '../../../icons';
import { TableSettingsPanelProps } from './TableSettingsPanel.types';
import { useTableSettings } from './useTableSettings';
import { SettingsItem } from './SettingsItem';
import { Button } from '../../../button/Button';
import { StrictModeDroppable } from '../../../dnd/StrictModeDroppable';

export function TableSettingsPanel<TRow extends TableRow>(props: TableSettingsPanelProps<TRow>): JSX.Element {
	const { testid, show, onClose, columns, columnsOrder, hiddenColumns, onSubmit } = props;

	const { order, hidden, onDragEnd, isHideLockedFor, toggleHide } = useTableSettings(columnsOrder, columns, show, hiddenColumns);

	return (
		<Transition show={show}>
			<Dialog onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-in-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in-out duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-opposite bg-opacity-50 transition-opacity" />
				</Transition.Child>
				<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
					<Transition.Child
						as={Fragment}
						enter="transform transition duration-500"
						enterFrom="translate-x-full"
						enterTo="translate-x-0"
						leave="transform transition duration-500"
						leaveFrom="translate-x-0"
						leaveTo="translate-x-full"
					>
						<Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
							<div className="flex h-full flex-col overflow-y-auto bg py-6 text">
								<div className="flex px-5">
									<Dialog.Title className="flex-1 text-lg font-medium">Table settings</Dialog.Title>
									<button type="button" className="hover:text-light rounded-md focus:outline-none" onClick={onClose}>
										<div className="h-5 w-5">
											<XIcon />
										</div>
									</button>
								</div>
								<div className="mt-6 h-full flex-1 space-y-7 px-5">
									<div className="space-y-2">
										<h2 className="font-medium">Columns</h2>
										<DragDropContext onDragEnd={onDragEnd}>
											<StrictModeDroppable droppableId="dnd">
												{(provided) => (
													<div className="w-full" ref={provided.innerRef} {...provided.droppableProps}>
														{order
															.filter((c) => !columns[c]?.static)
															.map((columnKey, i) => (
																<SettingsItem
																	index={i}
																	key={columnKey.toString()}
																	testid={`${testid}-${columnKey.toString()}`}
																	columnKey={columnKey.toString()}
																	columnTitle={columns[columnKey]?.title}
																	hidden={hidden.has(columnKey)}
																	hiddenLocked={isHideLockedFor(columnKey)}
																	onHideToggle={() => toggleHide(columnKey)}
																/>
															))}
														{provided.placeholder}
													</div>
												)}
											</StrictModeDroppable>
										</DragDropContext>
									</div>
									<div className="text-right">
										<Button
											testid="table-settings-button-apply"
											variant="filled"
											onClick={() => {
												onSubmit(order, hidden);
												onClose();
											}}
										>
											Apply
										</Button>
									</div>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

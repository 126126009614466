import { CSSProperties, MouseEvent as ReactMouseEvent } from 'react';

export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export function calculateNewWindowPosition(
	e: ReactMouseEvent | MouseEvent,
	cursorPosition: CSSProperties,
	modalElement: HTMLDivElement,
): CSSProperties {
	const topOffset = modalElement.offsetTop - ((cursorPosition.top! as number) - e.clientY);
	const leftOffset = modalElement.offsetLeft - ((cursorPosition.left! as number) - e.clientX);

	return {
		top: clamp(topOffset, 0, window.visualViewport!.height - modalElement.clientHeight),
		left: clamp(leftOffset, 0, window.visualViewport!.width - modalElement.clientWidth),
	};
}

export function getCurrentCursorPosition(e: ReactMouseEvent | MouseEvent): CSSProperties {
	return {
		top: clamp(e.clientY, 0, window.visualViewport!.height),
		left: clamp(e.clientX, 0, window.visualViewport!.width),
	};
}

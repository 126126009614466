import { TableColumnFilter, TableRow, TableSelectedFilter } from '@ipis-ui-kit/table-types';
import { useCallback, useState } from 'react';
import { TableFilterModalState, TableProps } from './Table.types';
import { CustomizableTable } from './CustomizableTable';
import { Pagination } from './pagination/Pagination';
import { TableFilterModal } from './filtration/TableFilterModal';
import { LoadingContainer } from '../../loading/LoadingContainer';

export function Table<TRow extends TableRow>(props: TableProps<TRow>): JSX.Element {
	const { hideSettings, hideRowOptions, noResize, selectable } = props;
	const { testid, isLoading, columns, data, ordering, filters, filtering, paging, totalRecords } = props;
	const { hiddenColumns, columnOrder, columnSizes } = props;

	const { onRowDoubleClick, onRowContextMenu } = props;
	const { onFilterClear, onOrderChanged, onPageChanged, onFiltersSelected } = props;
	const { onAllRowsSelect, onSelectedRowChange, onSelectedRowsClear } = props;
	const { onHiddenColumnsSet, onColumnsOrderChange, onColumnResize } = props;

	const [modalState, setModalState] = useState<TableFilterModalState<TRow>>({ visible: false });

	const onModalCancel = useCallback(() => {
		setModalState((prev) => ({ ...prev, visible: false }));
	}, []);

	const onModalSubmit = useCallback(
		(columnKey: keyof TRow, selectedFilters: TableSelectedFilter<unknown>[]) => {
			onFiltersSelected!(columnKey, selectedFilters);
			setModalState((prev) => ({ ...prev, visible: false }));
		},
		[onFiltersSelected],
	);

	const handleFilterSet = useCallback(
		(columnKey: keyof TRow) => {
			const { title: modalTitle } = columns.filter((x) => x.columnKey === columnKey)[0];
			setModalState({ columnKey, modalTitle, visible: true });
		},
		[columns],
	);

	return (
		<div>
			<LoadingContainer testid={testid} isLoading={isLoading}>
				<CustomizableTable
					testid={testid}
					data={data}
					columns={columns}
					order={ordering}
					hiddenColumns={hiddenColumns}
					columnOrder={columnOrder}
					columnSizes={columnSizes}
					filter={filtering?.columnFilterState}
					hideSettings={hideSettings}
					hideRowOptions={hideRowOptions}
					selectable={selectable}
					noResize={noResize}
					onOrderChanged={onOrderChanged}
					onRowDoubleClick={onRowDoubleClick}
					onRowContextMenu={onRowContextMenu}
					onFilterSet={handleFilterSet}
					onFilterClear={onFilterClear}
					onAllRowsSelect={onAllRowsSelect}
					onSelectedRowChange={onSelectedRowChange}
					onSelectedRowsClear={onSelectedRowsClear}
					onHiddenColumnsSet={onHiddenColumnsSet}
					onColumnsOrderChange={onColumnsOrderChange}
					onColumnResize={onColumnResize}
				/>
				{paging?.enabled && (
					<Pagination
						testid={testid}
						currentPage={paging.currentPage}
						totalRecords={totalRecords!}
						pageLimit={paging.pageSize ?? 0}
						onPageChanged={onPageChanged!}
					/>
				)}
			</LoadingContainer>
			{modalState?.visible && modalState.columnKey && modalState.modalTitle && (
				<TableFilterModal
					testid={testid}
					columnKey={modalState.columnKey}
					title={modalState.modalTitle}
					filters={filters[modalState.columnKey] as TableColumnFilter<unknown>[]}
					selectedFilters={filtering?.selectedFilters[modalState.columnKey] as TableSelectedFilter<unknown>[]}
					onFiltersSelected={onModalSubmit}
					onCancel={onModalCancel}
				/>
			)}
		</div>
	);
}

import { useMemo } from 'react';
import { TableColumnOrder, TableOrder, TableRow } from '@ipis-ui-kit/table-types';
import { DataTableProps } from './DataTable.types';
import { CustomizableTable } from '../ui/CustomizableTable';

export function DataTable<TRow extends TableRow>(props: DataTableProps<TRow>): JSX.Element {
	const { testid, data, columns, hideRowOptions, hideSettings, noResize } = props;
	const { onRowContextMenu, onRowDoubleClick, onOrderChanged, onSelectedRowChange } = props;

	const filter = useMemo(() => columns.reduce((a, { columnKey }) => ({ ...a, [columnKey]: 'disabled' }), {}), [columns]);
	const order = useMemo<TableOrder<TRow>>(() => columns.reduce((a, { columnKey }) => ({ ...a, [columnKey]: { order: 'disabled' } }), {}), [columns]); // prettier-ignore
	const columnsOrder = useMemo<TableColumnOrder<TRow>>(() => columns.map((x) => x.columnKey), [columns]);

	return (
		<CustomizableTable
			testid={testid}
			data={data}
			columns={columns}
			filter={filter}
			order={order}
			hideRowOptions={hideRowOptions}
			noResize={noResize}
			hideSettings={hideSettings}
			onRowDoubleClick={onRowDoubleClick}
			onRowContextMenu={onRowContextMenu}
			onOrderChanged={onOrderChanged}
			onSelectedRowChange={onSelectedRowChange}
			columnOrder={columnsOrder}
		/>
	);
}

import { usePickerMonths } from '@ipis-ui-kit/react-headless';

import { Month } from '../items/Month';
import { MonthPickerPanelProps } from './MonthPickerPanel.types';

export function MonthPickerPanel(props: MonthPickerPanelProps): JSX.Element {
	const { testid } = props;

	const { months } = usePickerMonths();

	const getKey = (index: number) => index.toString();

	return (
		<div data-testid={testid} className="grid grid-cols-[repeat(3,_1fr)] justify-center gap-0.5">
			{months.map(({ date, label }, index) => (
				<Month testid={testid} date={date} monthLabel={label} key={getKey(index)} />
			))}
		</div>
	);
}

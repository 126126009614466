import { IconKasperskyLogo } from '../../icons/IconKasperskyLogo';
import { HeaderProps } from './Header.types';

export function Header({ onLogoClick, testid, children }: HeaderProps): JSX.Element {
	return (
		<header
			data-testid={`${testid}-header`}
			className="mb-1 w-full flex-shrink-0 flex-grow-0 bg shadow-[0_1px_4px_0_rgba(92,133,150,0.24)]"
		>
			<div className="m-auto mx-auto flex w-full justify-between gap-5 px-9 text">
				<span className="flex h-16 w-40 flex-shrink-0 cursor-pointer text-primary" role="presentation" onClick={onLogoClick}>
					<IconKasperskyLogo />
				</span>

				<span className="flex">{children}</span>
			</div>
		</header>
	);
}

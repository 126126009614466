import { OptionProps } from './Option.types';

export function Option(props: OptionProps): JSX.Element {
	const { icon, iconDirection, text, disabled, testid, onClick } = props;
	return (
		<div
			data-testid={`${testid}-option`}
			role="presentation"
			onClick={(e) => onClick && !disabled && onClick(e)}
			className={`flex select-none justify-between whitespace-nowrap rounded px-3 py-2 text hover:bg-grayscale-200 hover:font-medium
				${disabled ? 'pointer-events-none text-grayscale-300' : 'cursor-pointer'}`}
		>
			{icon && <span className={`flex w-4 flex-shrink-0 items-center ${iconDirection === 'end' && 'order-2'}`}>{icon}</span>}
			<span className="w-full px-1.5">{text}</span>
		</div>
	);
}

import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorContextParameters, ErrorLevel, OverlappingError } from './ErrorProvider.types';

const ErrorContext = createContext<ErrorContextParameters | null>(null);

export function ErrorProvider({ children }: PropsWithChildren<{}>): JSX.Element {
	const location = useLocation();

	const [errorLevel, setErrorLevel] = useState<ErrorLevel | null>(null);
	const [overlappingError, setOverlappingError] = useState<OverlappingError | null>(null);

	useEffect(() => {
		setOverlappingError(null);
		setErrorLevel(null);
	}, [location]);

	const overlappingErrorContextValue = useMemo<ErrorContextParameters>(
		() => ({
			errorLevel,
			overlappingError,
			setOverlappingError: (level: ErrorLevel, e: OverlappingError) => {
				setErrorLevel(level);
				setOverlappingError(e);
			},
		}),
		[errorLevel, setErrorLevel, overlappingError, setOverlappingError],
	);

	return <ErrorContext.Provider value={overlappingErrorContextValue}>{children}</ErrorContext.Provider>;
}

export const useError = (): ErrorContextParameters => {
	const contextData = useContext(ErrorContext);

	if (!contextData) {
		throw new Error(`${useError.name} must be used only inside ${ErrorProvider.name}.`);
	}

	return contextData;
};

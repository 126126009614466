import { PropsWithChildren } from 'react';
import { ArticleErrorParameters } from './ErrorBoundary.types';
import { ErrorProvider, useError } from './ErrorProvider';
import { OverlappingError } from './ErrorProvider.types';

export const useArticleError = (): ArticleErrorParameters => {
	const contextData = useError();

	if (!contextData) {
		throw new Error(`${useArticleError.name} must be used only inside ${ErrorProvider.name}.`);
	}

	return {
		error: contextData.overlappingError,
		setError: (e: OverlappingError) => contextData.setOverlappingError('article', e),
	};
};

export const ErrorBoundary = {
	Article: ({ children }: PropsWithChildren<{}>): JSX.Element => {
		const { overlappingError, errorLevel } = useError();
		const isArticleError = overlappingError && errorLevel === 'article';
		return <>{isArticleError ? <>{overlappingError.fallbackComponent?.()}</> : children}</>;
	},
};

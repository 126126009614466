import { useCallback, useMemo, useRef, useState } from 'react';
import { TableColumnExactOrderingState, TableColumnWidthPresets } from '@ipis-ui-kit/table-types';
import { Option } from '../../dropdown/Option';
import { TableHeaderProps } from './TableHeader.types';
import { IconFilter, IconFilterOff, IconSortAsc, IconSortDesc, IconSortOff, IconChevron } from '../../icons';
import { OptionMenuContainer } from '../../dropdown/OptionMenuContainer';
import { useColumnResize } from './useColumnResize';

const widthDictionary: { [key in TableColumnWidthPresets]: string } = {
	tiny: 'w-16',
	small: 'w-32',
	medium: 'w-48',
	large: 'w-72',
	auto: 'w-auto',
};

export function TableHeader(props: TableHeaderProps): JSX.Element {
	const { testid, title, widthPreset: width, filter, order, orderPriority, size: initialSize, hidden, noResize } = props;
	const { onFilterClear, onFilterSet, onOrderChanged, onResize } = props;

	const [expanded, setExpanded] = useState<boolean>(false);
	const optionMenuAnchor = useRef<HTMLDivElement>(null);
	const { onResizeStart } = useColumnResize(optionMenuAnchor, onResize);

	const widthClass = useMemo(
		() => (!width || width in widthDictionary) && widthDictionary[(width ?? 'auto') as TableColumnWidthPresets],
		[width],
	);

	const getNewOrder = () => (order === 'ascending' ? 'descending' : 'ascending');
	const switchOrder = () => {
		if (order !== 'disabled') onOrderChanged?.(getNewOrder(), true);
		setExpanded(false);
	};

	const changeOrder = useCallback(
		(changedOrder: TableColumnExactOrderingState | undefined, isBreakingChange: boolean) => {
			onOrderChanged?.(changedOrder, isBreakingChange);
			setExpanded(false);
		},
		[onOrderChanged],
	);

	const changeFilter = useCallback(() => {
		onFilterSet?.();
		setExpanded(false);
	}, [onFilterSet]);

	const clearFilter = useCallback(() => {
		onFilterClear?.();
		setExpanded(false);
	}, [onFilterClear]);

	const onColumnResize = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			if (!noResize) {
				onResizeStart(e);
			}
		},
		[noResize, onResizeStart],
	);

	return (
		<th
			data-testid={`${testid}-table-header`}
			className={`group h-0 overflow-hidden p-0 text-left font-medium
				${widthClass} ${hidden && 'hidden'}`}
			style={!widthClass && !initialSize ? { width } : { width: initialSize }}
		>
			<div ref={optionMenuAnchor} data-testid={`${testid}-table-header-inner`} className="relative h-full w-full">
				<div
					data-testid={`${testid}-table-header-resize-separator`}
					className={`absolute bottom-2 right-0 top-2 w-1 border-r border-primary ${noResize || 'cursor-col-resize'}`}
					role="presentation"
					onMouseDown={onColumnResize}
				/>

				<div className="flex h-full w-full items-center">
					<span
						data-testid={`${testid}-table-header-text`}
						className="flex flex-1 cursor-pointer items-center truncate text-primary"
						role="presentation"
						onClick={switchOrder}
					>
						<span data-testid={`${testid}-table-header-title`} className="truncate px-3 py-1 text-grayscale-700">
							{title}
						</span>
						{filter === 'filtering' && (
							<span className="ml-1 w-4 flex-shrink-0">
								<IconFilter />
							</span>
						)}
						{(order === 'descending' || order === 'ascending') && (
							<>
								<span className="ml-1 w-4 flex-shrink-0">{order === 'ascending' ? <IconSortAsc /> : <IconSortDesc />}</span>
								<sub data-testid={`${testid}-table-header-order-priority`} className="select-none">
									{orderPriority?.toString()}
								</sub>
							</>
						)}
					</span>

					{(order !== 'disabled' || filter !== 'disabled') && (
						<button
							data-testid={`${testid}-table-header-button`}
							className="invisible flex h-full items-center px-1.5 group-hover:visible"
							type="button"
							onClick={() => setExpanded((s) => !s)}
						>
							<span className="w-5 -rotate-90 text-grayscale-700">
								<IconChevron />
							</span>
						</button>
					)}
				</div>
			</div>

			<OptionMenuContainer
				testid={`${testid}-table-header-container`}
				anchor={optionMenuAnchor}
				width="medium"
				expanded={expanded}
				onCollapse={() => setExpanded(false)}
			>
				{order !== 'disabled' && (
					<Option
						testid={`${testid}-table-header-order-asc`}
						text="Ascending"
						icon={<IconSortAsc />}
						onClick={() => changeOrder('ascending', false)}
					/>
				)}
				{order !== 'disabled' && (
					<Option
						testid={`${testid}-table-header-order-desc`}
						text="Descending"
						icon={<IconSortDesc />}
						onClick={() => changeOrder('descending', false)}
					/>
				)}
				{(order === 'descending' || order === 'ascending') && (
					<Option
						testid={`${testid}-table-header-no-order`}
						text="Don't sort"
						icon={<IconSortOff />}
						onClick={() => changeOrder(undefined, false)}
					/>
				)}
				{filter !== 'disabled' && (
					<Option testid={`${testid}-table-header-filter`} text="Filter" icon={<IconFilter />} onClick={() => changeFilter()} />
				)}
				{filter === 'filtering' && (
					<Option
						testid={`${testid}-table-header-no-filter`}
						text="Clear filter"
						icon={<IconFilterOff />}
						onClick={() => clearFilter()}
					/>
				)}
			</OptionMenuContainer>
		</th>
	);
}

import { usePickerYears } from '@ipis-ui-kit/react-headless';
import { Year } from '../items/Year';
import { YearPickerPanelProps } from './YearPickerPanel.types';

export function YearPickerPanel(props: YearPickerPanelProps): JSX.Element {
	const { testid } = props;

	const { years } = usePickerYears();

	const getKey = (index: number) => index.toString();

	return (
		<div data-testid={testid} className="grid grid-cols-[repeat(4,_1fr)] justify-center gap-0.5">
			{years.map(({ date, label }, index) => (
				<Year testid={testid} date={date} yearLabel={label} key={getKey(index)} />
			))}
		</div>
	);
}

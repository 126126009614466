import { FormControlsProps } from './FormControls.types';

export function FormControls(props: FormControlsProps): JSX.Element {
	const { testid, children } = props;

	return (
		<div data-testid={`${testid}-form-controls`} className="flex flex-wrap justify-end gap-2">
			{children}
		</div>
	);
}

import { useRef } from 'react';
import { usePickerTime } from '@ipis-ui-kit/react-headless';
import { Time } from '../items/Time';
import { TimePanelProps } from './TimePanel.types';
import { IconChevron } from '../../../icons';
import { ScrollContainer } from '../../../scrollcontainer/ScrollContainer';

export function TimePanel(props: TimePanelProps): JSX.Element {
	const ref = useRef<HTMLDivElement>(null);
	const { testid } = props;
	const { hours, setNextHour, setPreviousHour } = usePickerTime();

	return (
		<div data-testid={testid} className="ml-2 flex h-full flex-col place-items-center">
			<span role="presentation" className="w-4 flex-shrink-0 rotate-90 cursor-pointer py-1" onClick={setPreviousHour}>
				<IconChevron />
			</span>
			<ScrollContainer testid={testid} ref={ref} orientation="vertical">
				<div className="grid grid-flow-row gap-y-0.5">
					{hours.map((h) => (
						<Time testid={testid} dateTime={h.date} hourLabel={h.hourLabel} key={h.hourLabel} scrollContainerRef={ref} />
					))}
				</div>
			</ScrollContainer>
			<span role="presentation" className="w-4 flex-shrink-0 -rotate-90 cursor-pointer py-1" onClick={setNextHour}>
				<IconChevron />
			</span>
		</div>
	);
}

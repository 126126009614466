const range = (from: number, to: number): number[] => [...Array(to - from + 1).keys()].map((i) => i + from);

export function getPageNumbers(currentPage: number, totalPages: number): number[] {
	const firstPage = 1;
	const maxVisiblePagesQuantity = 5;

	if (totalPages > maxVisiblePagesQuantity) {
		const startPage =
			currentPage < Math.floor(maxVisiblePagesQuantity / 2) + 1
				? firstPage
				: Math.min(totalPages - maxVisiblePagesQuantity + 1, currentPage - Math.floor(maxVisiblePagesQuantity / 2));
		const endPage =
			totalPages - currentPage < Math.floor(maxVisiblePagesQuantity / 2) + 1
				? totalPages
				: Math.max(maxVisiblePagesQuantity, currentPage + Math.floor(maxVisiblePagesQuantity / 2));

		return range(startPage, endPage);
	}

	return range(firstPage, totalPages);
}

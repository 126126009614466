/**
 * Formats utc date as yyyy-MM-dd HH:mm:ss.
 * Used for display only
 * @date date
 */
export const formatUtcDateTime = (date: Date): string => date.toISOString().substring(0, 19).replace('T', ' ');

/**
 * Formats utc date as yyyy-MM-dd.
 * @date date
 */
export const formatUtcDate = (date: Date): string => date.toISOString().substring(0, 10);

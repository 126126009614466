export function IconCheck() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="100%">
			<path
				fill="currentColor"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.1253 6.13103L7.97281 16.1908L2 10.1173L4.13898 8.01376L8.00032 11.9402L16.0137 4L18.1253 6.13103Z"
			/>
		</svg>
	);
}

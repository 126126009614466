export function IconSpinner() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="100%">
			<g fill="none" fillRule="evenodd" strokeWidth="5">
				<path d="M24,3 A21,21 0 0,0 9.150757595,38.849242405" stroke="#f2f2f2" strokeOpacity="70%" className="segment-1" />
				<path d="M9.150757595,38.849242405 A21,21 0 1,0 24,3" stroke="currentColor" className="segment-2" />
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 24 24"
					to="360 24 24"
					dur="1s"
					repeatCount="indefinite"
				/>
			</g>
		</svg>
	);
}

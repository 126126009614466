import { IconSpinner } from '../icons/IconSpinner';
import { LoaderProps } from './Loader.types';

export function Loader({ testid, noOverlay }: LoaderProps): JSX.Element {
	return (
		<div
			data-testid={`${testid}-loader`}
			className={`absolute left-0 top-0 flex h-full w-full items-center justify-center 
				${!noOverlay && 'bg-grayscale-300 bg-opacity-30'}`}
		>
			<div className="w-14 text-primary">
				<IconSpinner />
			</div>
		</div>
	);
}

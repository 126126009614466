import { useState } from 'react';
import { TableColumn } from '@ipis-ui-kit/table-types';
import { useArticleError } from '../../app/errors/ErrorBoundary';
import { Button } from '../../components/button/Button';
import { CheckBox } from '../../components/checkbox/CheckBox';
import { Divider } from '../../components/divider/Divider';
import { IconPerson } from '../../components/icons/IconPerson';
import { TextField } from '../../components/text-field/TextField';
import { TextFieldVariant } from '../../components/text-field/TextField.types';
import { Select } from '../../components/select/Select';
import { Forbidden } from '../Errors/Forbidden';
import { NotFound } from '../Errors/NotFound';
import { Unauthorized } from '../Errors/Unauthorized';
import { Unknown } from '../Errors/Unknown';
import { IconRefresh } from '../../components/icons/IconRefresh';
import { SelectItem } from '../../components/select/Select.types';
import { IconGlobe } from '../../components/icons/IconGlobe';
import { NoAccountEmail } from '../Errors/NoAccountEmail';
import { EmailNotVerified } from '../Errors/EmailNotVerified';
import { useAlerts } from '../../app/alerts';

import * as stringFilters from '../../components/table/odata/filters/oDataTableStringFilters';
import * as numberFilters from '../../components/table/odata/filters/oDataTableNumberFilters';
import { DataTable, DateTimeInput } from '../../components';

export function Test(): JSX.Element {
	const { setError } = useArticleError();

	const [inputValue, setInputValue] = useState<string>('');
	const [inputIconRight, setInputIconRight] = useState<JSX.Element>();
	const [inputIconLeft, setInputIconLeft] = useState<JSX.Element>();
	const [inputVariant, setInputVariant] = useState<TextFieldVariant>();
	const [inputMultiline, setInputMultiline] = useState<boolean>();
	const [inputHelperText, setInputHelperText] = useState<string>();
	const [inputDisabled, setInputDisabled] = useState<boolean>();
	const { addAlert, clearAlerts, removeAlert } = useAlerts();

	const [checkboxValue, setCheckboxValue] = useState<boolean>(false);

	const [selectedItem, setSelectedItem] = useState<SelectItem>();
	const [selectError, setSelectError] = useState<boolean>(false);
	const [selectDisabled, setSelectDisabled] = useState<boolean>(false);
	const [selectOptionsCount, setSelectOptionsCount] = useState<number>(1);

	const [dateTimePickerDate, setDateTimePickerDate] = useState<Date | null | undefined>(null);

	const columns: TableColumn<{ productCode: string; productName: string; productTier: string }>[] = [
		{
			columnKey: 'productCode',
			isRowKey: true,
			title: 'Product Code',
			width: 'medium',
			order: 'ascending',
			orderPriority: 2,
			filters: numberFilters.all({ min: 1 }),
		},
		{
			columnKey: 'productName',
			title: 'Product Name',
			filters: stringFilters.all(),
		},
		{
			columnKey: 'productTier',
			title: 'Product Tier',
			order: 'ascending',
			orderPriority: 1,
			filters: stringFilters.all(),
		},
	];

	const data = [
		{
			productCode: '1',
			productName: 'Test1',
			productTier: 'Basic',
		},
		{
			productCode: '2',
			productName: 'Test2',
			productTier: 'Basic',
		},
		{
			productCode: '3',
			productName: 'Test3',
			productTier: 'Premium',
		},
	];

	return (
		<>
			<div className="grid w-56 flex-none grid-flow-row auto-rows-min gap-3 p-3 pr-1.5">
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <NotFound /> })}>
					Error [Not found]
				</Button>
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <Unauthorized /> })}>
					Error [Unauthorized]
				</Button>
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <Forbidden /> })}>
					Error [Forbidden]
				</Button>
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <Unknown /> })}>
					Error [Unknown error]
				</Button>
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <NoAccountEmail /> })}>
					Error [No email]
				</Button>
				<Button testid="" onClick={() => setError({ fallbackComponent: () => <EmailNotVerified /> })}>
					Error [Email is not verified]
				</Button>
				<br />
			</div>

			<div>
				<CheckBox testid="" checked={selectError} disabled={selectDisabled} onChange={(e) => setSelectError(e.target.checked)}>
					Error
				</CheckBox>
				<CheckBox testid="" checked={selectDisabled} disabled={selectError} onChange={(e) => setSelectDisabled(e.target.checked)}>
					Disabled
				</CheckBox>
				<div>Options count</div>
				<input
					type="number"
					min={1}
					max={50}
					value={selectOptionsCount}
					onChange={(e) => setSelectOptionsCount(+e.target.value)}
				/>
				<TextField
					testid=""
					value={inputValue}
					iconLeft={inputIconLeft ? <>{inputIconLeft}</> : undefined}
					iconRight={inputIconRight ? <>{inputIconRight}</> : undefined}
					variant={inputVariant}
					multiline={inputMultiline}
					error={!!inputHelperText}
					helperText={inputHelperText}
					disabled={inputDisabled}
					onChange={(e) => setInputValue(e.target.value)}
				/>
				<br />
				<TextField
					testid="date-filter"
					type="date"
					// step={options?.step ? options.step : 1}
					// min={options?.min && toDisplayDate(options.min)}
					// max={options?.max && toDisplayDate(options.max)}}
					value=""
				/>
				<br />
				Default
				<DateTimeInput
					testid=""
					value={dateTimePickerDate}
					timeZone="UTC"
					dateFormat="yyyy-MM-dd HH:mm:ss"
					onChange={(d) => {
						console.log(!d ? d : d.toISOString());
						setDateTimePickerDate(d);
					}}
				/>
				Default (no time)
				<DateTimeInput
					testid=""
					value={dateTimePickerDate}
					mode="year-month-day"
					timeZone="UTC"
					dateFormat="yyyy-MM-dd HH:mm:ss"
					onChange={(d) => {
						console.log(!d ? d : d.toISOString());
						setDateTimePickerDate(d);
					}}
				/>
				Month only
				<DateTimeInput
					testid=""
					value={dateTimePickerDate}
					mode="year-month"
					timeZone="UTC"
					dateFormat="yyyy-MM-dd HH:mm:ss"
					onChange={(d) => {
						console.log(!d ? d : d.toISOString());
						setDateTimePickerDate(d);
					}}
				/>
				Year only
				<DateTimeInput
					testid=""
					value={dateTimePickerDate}
					mode="year"
					timeZone="UTC"
					dateFormat="yyyy-MM-dd HH:mm:ss"
					onChange={(d) => {
						console.log(!d ? d : d.toISOString());
						setDateTimePickerDate(d);
					}}
				/>
				<br />
				<Select
					error={selectError}
					disabled={selectDisabled}
					testid=""
					selectedId={selectedItem?.id}
					icon={<IconRefresh />}
					helperText="Very long error text!"
					items={[...Array.from({ length: selectOptionsCount }, (_, i) => i + 1)].map((x) => ({
						id: `Option-${x}`,
						value: `${Math.random() * 10}-Option-${x}`,
						disabled: Math.random() < 0.5,
						icon: <IconGlobe />,
					}))}
					onChange={(i) => {
						setSelectedItem(i);
					}}
				/>
			</div>
			<br />

			<div className="grid w-80 flex-none grid-flow-row auto-rows-min gap-5 p-3 pr-1.5">
				<Divider />
				<br />
				<Button testid="" icon={<IconGlobe />} onClick={() => alert('clicked')}>
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')}>
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} variant="filled">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} variant="outlined">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} variant="dangerous">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} disabled variant="filled">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} disabled variant="outlined">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} disabled variant="dangerous">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow>
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow variant="filled">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow variant="outlined">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow variant="dangerous">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow disabled variant="filled">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow disabled variant="outlined">
					Lorem ipsum dolor sit amet!
				</Button>
				<Button testid="" onClick={() => alert('clicked')} shadow disabled variant="dangerous">
					Lorem ipsum dolor sit amet!
				</Button>
			</div>

			<div className="grid flex-none grid-flow-row auto-rows-min gap-5 p-3 pr-1.5">
				<Divider />
				<div>
					Icon Left
					<input type="checkbox" onChange={(e) => setInputIconLeft(e.target.checked ? <IconPerson /> : undefined)} />
				</div>
				<div>
					Icon Right
					<input type="checkbox" onChange={(e) => setInputIconRight(e.target.checked ? <IconPerson /> : undefined)} />
				</div>
				<div>
					Underlined
					<input type="checkbox" onChange={(e) => setInputVariant(e.target.checked ? 'underlined' : 'standard')} />
				</div>
				<div>
					Multiline
					<input type="checkbox" onChange={(e) => setInputMultiline(!!e.target.checked)} />
				</div>
				<div>
					Error
					<input
						type="checkbox"
						onChange={(e) =>
							setInputHelperText(
								e.target.checked
									? 'Error Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet!'
									: undefined,
							)
						}
					/>
				</div>
				<div>
					Disabled
					<input type="checkbox" onChange={(e) => setInputDisabled(!!e.target.checked)} />
				</div>

				<TextField
					testid=""
					value={inputValue}
					iconLeft={inputIconLeft ? <>{inputIconLeft}</> : undefined}
					iconRight={inputIconRight ? <>{inputIconRight}</> : undefined}
					variant={inputVariant}
					multiline={inputMultiline}
					error={!!inputHelperText}
					helperText={inputHelperText}
					disabled={inputDisabled}
					onChange={(e) => setInputValue(e.target.value)}
				/>
				<TextField
					testid=""
					value={inputValue}
					iconLeft={inputIconLeft ? <>{inputIconLeft}</> : undefined}
					iconRight={inputIconRight ? <>{inputIconRight}</> : undefined}
					variant={inputVariant}
					multiline={inputMultiline}
					error={!!inputHelperText}
					helperText={inputHelperText}
					disabled={inputDisabled}
					placeholder="Hold my place"
					onChange={(e) => setInputValue(e.target.value)}
				/>

				{!inputMultiline && (
					<>
						<TextField
							testid=""
							type="number"
							value={inputValue}
							iconLeft={inputIconLeft ? <>{inputIconLeft}</> : undefined}
							iconRight={inputIconRight ? <>{inputIconRight}</> : undefined}
							variant={inputVariant}
							multiline={inputMultiline}
							error={!!inputHelperText}
							helperText={inputHelperText}
							disabled={inputDisabled}
							placeholder="Hold my place"
							onChange={(e) => setInputValue(e.target.value)}
						/>

						<TextField
							testid=""
							type="password"
							value={inputValue}
							iconLeft={inputIconLeft ? <>{inputIconLeft}</> : undefined}
							iconRight={inputIconRight ? <>{inputIconRight}</> : undefined}
							variant={inputVariant}
							multiline={inputMultiline}
							error={!!inputHelperText}
							helperText={inputHelperText}
							disabled={inputDisabled}
							placeholder="Hold my place"
							onChange={(e) => setInputValue(e.target.value)}
						/>
					</>
				)}
				<Divider />
			</div>

			<DataTable hideRowOptions columns={columns} data={data} testid="asd" />

			<div className="grid flex-none grid-flow-row auto-rows-min gap-5 p-3 pr-1.5">
				<Divider />
				<CheckBox testid="" checked={checkboxValue} onChange={(e) => setCheckboxValue(e.target.checked)} />
				<CheckBox testid="" checked={checkboxValue} onChange={(e) => setCheckboxValue(e.target.checked)}>
					Some checkbox text
				</CheckBox>
				<CheckBox testid="" checked={checkboxValue} onChange={(e) => setCheckboxValue(e.target.checked)}>
					Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem
					ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet!
				</CheckBox>
				<CheckBox
					testid=""
					error
					helperText="Error Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Error Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet!"
					checked={checkboxValue}
					onChange={(e) => setCheckboxValue(e.target.checked)}
				>
					Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem
					ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet! Lorem ipsum dolor sit amet!
				</CheckBox>
				<CheckBox testid="" disabled checked={checkboxValue} onChange={(e) => setCheckboxValue(e.target.checked)}>
					Some disabled checkbox tex
				</CheckBox>
			</div>
			<div className="grid w-80 flex-none grid-flow-row auto-rows-min gap-5 p-3 pr-1.5">
				<Button
					testid=""
					onClick={() =>
						addAlert({
							type: 'error',
							message: `Error message ${new Date().getMilliseconds()}`,
						})
					}
				>
					Add error message
				</Button>

				<Button
					testid=""
					onClick={() =>
						addAlert({
							type: 'info',
							message: `INfo infor infr`,
						})
					}
				>
					Add info p
				</Button>

				<Button testid="" onClick={() => removeAlert(`INfo infor infr`, 'info')}>
					Remove info Alert
				</Button>

				<Button testid="" onClick={() => clearAlerts()}>
					Clear all alerts
				</Button>

				<Button
					testid="multiple strings error"
					onClick={() =>
						addAlert({
							type: 'error',
							message: `Error Message cause of not cause of not cause of not cause of not because somethin happend: error Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
						})
					}
				>
					Add multiple strings error
				</Button>
			</div>
		</>
	);
}

import { Error } from '../components/error/Error';
import { Button } from '../../components/button/Button';

export function EmailNotVerified(): JSX.Element {
	const redirect = (path: string) => window.location.replace(path);

	return (
		<div className="flex h-full flex-col items-center justify-center">
			<div>
				<Error
					title="Email in your Kaspersky Account is not verified"
					description="To get access to CBA Partner Portal please verify email in your Kaspersky Account."
					testid="email-not-verified-error"
				/>

				<div className="flex justify-center">
					<Button testid="ok-email-not-verified" onClick={() => redirect('/account/cbaportallogout')}>
						OK
					</Button>
				</div>
			</div>
		</div>
	);
}

export function IconOnePage() {
	return (
		<svg viewBox="0 0 20 20" width="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M16.5 3.5H3.5V16.5H16.5V3.5ZM2 2V18H18V2H2Z" fill="currentColor" />
			<rect x="5" y="6.25" width="10" height="1.5" fill="currentColor" />
			<rect x="5" y="9.25" width="10" height="1.5" fill="currentColor" />
			<rect x="5" y="12.25" width="10" height="1.5" fill="currentColor" />
		</svg>
	);
}

/* eslint-disable react/jsx-props-no-spreading */
import { Fragment } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TableRow } from '@ipis-ui-kit/table-types';
import { SettingsItemProps } from './SettingsItem.types';
import { Portal } from '../../../portal/Portal';
import { IconDrag, IconVisibility, IconVisibilityOff } from '../../../icons';

export function SettingsItem<TDataRow extends TableRow>(props: SettingsItemProps<TDataRow>): JSX.Element {
	const { testid, index, columnKey, columnTitle, hidden, hiddenLocked, onHideToggle } = props;

	return (
		<Draggable key={columnKey as string} draggableId={`${columnKey.toString()}-drag`} index={index}>
			{(provided, snapshot) => {
				let Wrapper = Fragment;
				if (snapshot.isDragging) {
					// @ts-ignore
					Wrapper = Portal;
				}

				return (
					<Wrapper>
						<div
							ref={provided.innerRef}
							className="border-normal my-1.5 flex w-full items-center rounded-md border border-grayscale-300 bg py-1.5 text-sm"
							data-testid={`${testid}-settings-item`}
							{...provided.draggableProps}
						>
							<div {...provided.dragHandleProps} className="ml-1 mr-1 flex h-5 w-5 items-center">
								<IconDrag />
							</div>
							<div className="flex w-full flex-row justify-between">
								<div className="truncate">{columnTitle}</div>
								<div className="flex items-center pr-1">
									<span
										role="presentation"
										onClick={onHideToggle}
										data-testid={`${testid}-visibility-icon`}
										className={`mr-1 w-5 cursor-pointer 
											${hiddenLocked ? 'pointer-events-none text-grayscale-300' : 'pointer-events-auto'}`}
									>
										{hidden ? <IconVisibilityOff /> : <IconVisibility />}
									</span>
								</div>
							</div>
						</div>
					</Wrapper>
				);
			}}
		</Draggable>
	);
}

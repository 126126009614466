import { flip, inline, offset, shift } from '@floating-ui/react-dom';
import { MouseEvent, RefObject } from 'react';
import { OptionMenuAnimationDirection } from './OptionMenu.types';
import { OptionMenuContainerProps } from './OptionMenuContainer.types';

export const isContextMenu = (anchor: MouseEvent | RefObject<Element>): boolean => !('current' in anchor);

export const getFloatingReference = (anchor: MouseEvent | RefObject<Element>) => {
	if (isContextMenu(anchor)) {
		const { clientX, clientY } = anchor as MouseEvent;

		const virtualElementParameters = {
			width: 0,
			height: 0,
			x: clientX,
			y: clientY,
			top: clientY,
			left: clientX,
			right: clientX,
			bottom: clientY,
		};

		const virtualElement = {
			getBoundingClientRect: () => virtualElementParameters,
			getClientRects: () => virtualElementParameters,
		};

		return virtualElement;
	}
	return (anchor as RefObject<Element>).current;
};

export const getAnimation = (
	anchor: MouseEvent | RefObject<Element> | undefined,
	computedY: number | null,
): OptionMenuAnimationDirection => {
	if (!anchor) return 'bottom';

	const anchorY = isContextMenu(anchor) ? (anchor as MouseEvent).clientY : (anchor as RefObject<Element>).current?.clientTop;

	return anchorY && computedY && computedY <= anchorY ? 'bottom' : 'top';
};

export const getFloatingParams = (props: OptionMenuContainerProps) => {
	const defaultPlacement = props.anchor && isContextMenu(props.anchor) ? 'bottom-start' : 'bottom-end';
	return {
		placement: props.placement ?? defaultPlacement,
		middleware: [...(props.noFlip ? [] : [flip()]), inline(), shift(), offset(props.offset ?? 2)],
	};
};

import { Error } from '../components/error/Error';

export function NoAccountEmail(): JSX.Element {
	return (
		<Error
			title="There is no email in your Kaspersky Account"
			description="To get access to CBA Partner Portal please set up email in your Kaspersky Account."
			testid="no-account-email-error"
		/>
	);
}

import { useFloating } from '@floating-ui/react-dom';
import { Listbox } from '@headlessui/react';
import { CSSProperties, Fragment, useMemo } from 'react';
import { whileElementsMounted, fullSize } from '../../lib/floating';
import { IconChevron } from '../icons/IconChevron';
import { Portal } from '../portal/Portal';
import { SelectItemsDictionary, SelectProps } from './Select.types';
import { SelectItem } from './SelectItem';

const styles = {
	enabled: 'bg',
	disabled: 'pointer-events-none bg-grayscale-100',
	border: 'border-grayscale-300 hover:border-highlight group-hover:border-highlight',
	activeBorder: 'border-b-primary-highlight',
	errorBorder: 'border-danger hover:border-danger-highlight group-hover:border-danger-highlight',
};

export function Select(props: SelectProps): JSX.Element {
	const { testid, icon, placeholder, items, selectedId, disabled, error, helperText, onBlur, onChange } = props;

	if (error && disabled) {
		throw Error('Disabled select can not be errored');
	}

	const itemsDictionary = useMemo<SelectItemsDictionary>(() => items.reduce((r, f) => ({ ...r, [f.id]: f }), {}), [items]);

	const { x, y, reference, floating, strategy } = useFloating({ middleware: [fullSize()], whileElementsMounted });
	const position: CSSProperties = { top: y ?? 0, left: x ?? 0, position: strategy };

	return (
		<div>
			<Listbox value={selectedId && itemsDictionary[selectedId]} disabled={disabled} onChange={onChange}>
				{({ open }) => (
					<>
						<Listbox.Button
							ref={reference}
							as="div"
							data-testid={testid}
							tabIndex={0}
							onBlur={onBlur}
							className={`group relative flex min-w-min items-center gap-2 border-2 border-b-0 px-3 py-2 outline-none transition-all
								${disabled ? styles.disabled : styles.enabled}
								${error ? styles.errorBorder : styles.border}`}
						>
							{icon && (
								<div
									data-testid={`${testid}-icon`}
									className={`w-6 flex-shrink-0 flex-grow-0 self-center 
										${open && !error ? 'text-primary' : 'text-grayscale-400'}`}
								>
									{icon}
								</div>
							)}

							<span
								data-testid={`${testid}-selected-value`}
								className={`flex-grow self-center 
									${selectedId ? 'font-medium text-grayscale-700' : 'font-light text-grayscale-500'}`}
							>
								{selectedId ? itemsDictionary[selectedId].value : placeholder ?? <>&nbsp;</>}
							</span>

							<div
								data-testid={`${testid}-toggle-icon`}
								className={`block w-4 flex-shrink-0 transition-all 
									${open ? 'rotate-90' : '-rotate-90'} 
									${disabled ? 'text-grayscale-300' : 'text'}`}
							>
								<IconChevron />
							</div>

							<div
								data-testid={`${testid}-underline`}
								className={`absolute -left-0.5 -right-0.5 bottom-0 border-b-2 transition-all
									${error && styles.errorBorder}
									${!error && (open ? styles.activeBorder : styles.border)}`}
							/>
						</Listbox.Button>

						{open && (
							<Portal>
								<div ref={floating} style={position} data-testid={`${testid}-menu`}>
									<Listbox.Options className="max-h-64 overflow-y-auto border-2 border-t-0 border-grayscale-300 bg outline-none">
										{items?.map((item) => (
											<SelectItem key={item.id} item={item} testid={testid} />
										))}
									</Listbox.Options>
								</div>
							</Portal>
						)}
					</>
				)}
			</Listbox>

			{error && helperText && (
				<div data-testid={`${testid}-error`} className="text-xs font-light text-danger">
					{helperText}
				</div>
			)}
		</div>
	);
}

import { useNavigate } from 'react-router-dom';
import { AlertBoundary } from '../../app/alerts';
import { ErrorBoundary } from '../../app/errors/ErrorBoundary';
import { Header, Main, Footer, Layout as LayoutContainer } from '../../components/layout';
import { AccountMenu } from './AccountMenu';
import { Routing } from './Routing';

export function Layout(): JSX.Element {
	const navigate = useNavigate();

	return (
		<LayoutContainer testid="app">
			<AlertBoundary />
			<Header testid="app" onLogoClick={() => navigate('/')}>
				<AccountMenu />
			</Header>

			<Main testid="app">
				<ErrorBoundary.Article>
					<Routing />
				</ErrorBoundary.Article>
			</Main>

			<Footer testid="app" />
		</LayoutContainer>
	);
}

import { Loader } from './Loader';
import { LoadingContainerProps } from './LoadingContainer.types';

export function LoadingContainer(props: LoadingContainerProps): JSX.Element {
	const { testid, children, isLoading, noLoaderOverlay, noContentWhileLoading } = props;

	return (
		<div className="min-h-20 relative h-full w-full" data-testid={`${testid}-loading-container`}>
			{!(isLoading && noContentWhileLoading) && children}
			{isLoading && <Loader testid={testid} noOverlay={noLoaderOverlay} />}
		</div>
	);
}

import axios from 'axios';

export const configureAxiosMiddlewares = () => {
	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response.status === 401) {
				window.location.replace('/account/signin');
			}
			return Promise.reject(error);
		},
	);
};

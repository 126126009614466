import { useUserIdentity } from '../../app/security/UserIdentityProvider';
import { IconPerson } from '../../components/icons';
import { HeaderMenu, HeaderMenuItem } from '../../components/layout';

export function AccountMenu(): JSX.Element {
	const { identity, isAuthenticated } = useUserIdentity();
	const redirect = (path: string) => window.location.replace(path);

	return (
		<HeaderMenu testid="account" icon={<IconPerson />} text={identity?.email ?? 'My Account'} filled>
			{isAuthenticated && (
				<HeaderMenuItem testid="acc-sign-out" text="Sign out" filled onClick={() => redirect('/account/logout')} />
			)}
		</HeaderMenu>
	);
}

import { forwardRef } from 'react';
import { ScrollContainerOrientation, ScrollContainerProps } from './ScrollContainer.types';

const orientationDictionary: { [key in ScrollContainerOrientation]: string } = {
	vertical: 'overflow-x-hidden overflow-y-auto',
	horizontal: 'overflow-x-auto overflow-y-hidden',
	both: 'overflow-x-auto overflow-y-auto',
};

export const ScrollContainer = forwardRef<HTMLDivElement, ScrollContainerProps>((props, ref): JSX.Element => {
	const { children, testid, className, orientation } = props;

	return (
		<div data-testid={`${testid}-scroll`} ref={ref} className={`scrollbar ${orientationDictionary[orientation]} ${className}`}>
			{children}
		</div>
	);
});

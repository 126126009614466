import { useRef } from 'react';
import { useTime } from '@ipis-ui-kit/react-headless';
import { TimeProps } from './Time.types';

export function Time(props: TimeProps): JSX.Element {
	const scrollContainerItemRef = useRef<HTMLDivElement>(null);

	const { testid, dateTime, hourLabel, scrollContainerRef } = props;

	const { isSelected, onSelect, onKeyDown } = useTime({ dateTime, scrollContainerRef, scrollContainerItemRef });

	return (
		<div
			role="toolbar"
			tabIndex={0}
			data-testid={testid}
			className={`cursor-pointer select-none overscroll-auto rounded px-2 py-1.5 text-center text-xs outline-offset-[-1px] focus:outline-1 focus:outline
				${isSelected ? 'bg-primary text-opposite' : 'bg text'}`}
			onClick={onSelect}
			onKeyDown={onKeyDown}
			ref={scrollContainerItemRef}
		>
			{hourLabel}
		</div>
	);
}

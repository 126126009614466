/**
 * Formats size in bytes into size units (Kb, Mb)
 * Used for display only
 * @size number
 */
export const formatFileSize = (size: number): string => {
	const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

	const multiplier = 1024;
	const sizes = ['Bytes', 'Kb', 'Mb'];

	const index = clamp(Math.floor(Math.log(size) / Math.log(multiplier)), 1, sizes.length - 1);

	return `${parseFloat((size / multiplier ** index).toFixed(2))} ${sizes[index]}`;
};
